import Api from '../services/api';
// eslint-disable-next-line
export default {
    //create new orderList    
    async login (data) {
      let authData, result;
      try {
        authData = await Api.login(data)
        result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    
    async adminGetUser (data) {
      let authData, result;
      try {
            authData = await Api.adminGetUser(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updateProfile (data) {
      let authData, result;
      try {
            authData = await Api.updateProfile(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async orderList (data) {
      let authData, result;
      try {
            authData = await Api.orderList(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async deleteOrder (data) {
      let authData, result;
      try {
            authData = await Api.deleteOrder(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updateDriver (data) {
      let authData, result;
      try {
            authData = await Api.updateDriver(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async clientList (data) {
      let authData, result;
      try {
            authData = await Api.clientList(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async driverList (data) {
      let authData, result;
      try {
            authData = await Api.driverList(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async adminList (data) {
      let authData, result;
      try {
            authData = await Api.adminList(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async getVehicle (data) {
      let authData, result;
      try {
            authData = await Api.getVehicle(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async vehicleType (data) {
      let authData, result;
      try {
            authData = await Api.vehicleType(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async newAdmin (data) {
      let authData, result;
      try {
            authData = await Api.newAdmin(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async topDrivers (data) {
      let authData, result;
      try {
            authData = await Api.topDrivers(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async totalPedidos (data) {
      let authData, result;
      try {
            authData = await Api.totalPedidos(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async mail (data) {
      let authData, result;
      try {
            authData = await Api.mail(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async income (data) {
      let authData, result;
      try {
            authData = await Api.income(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async forgot (data) {
      let authData, result;
      try {
            authData = await Api.forgot(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async checkForgetTime (data) {
      let authData, result;
      try {
            authData = await Api.checkForgetTime(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updatePassword (data) {
      let authData, result;
       try {
            authData = await Api.updatePassword(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async saveMail (data) {
      let authData, result;
       try {
            authData = await Api.saveMail(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async canceledReason (data) {
      let authData, result;
       try {
            authData = await Api.canceledReason(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async getSpecialPriceByVehicle (data) {
      let authData, result;
       try {
            authData = await Api.getSpecialPriceByVehicle(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async createSpecialPrice(data) {
      let authData, result;
       try {
            authData = await Api.createSpecialPrice(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updateSpecialPrice (data) {
      let authData, result;
       try {
            authData = await Api.updateSpecialPrice(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updateKmRound (data) {
      let authData, result;
       try {
            authData = await Api.updateKmRound(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updateVehiclePrices (data) {
      let authData, result;
       try {
            authData = await Api.updateVehiclePrices(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async saveRestrictedArea(data){
      let authData, result;
      try {
           authData = await Api.saveRestrictedArea(data)
           result = authData.data;
      } catch (error) {
        result = false;
      }
      return result;
    },
    async getSpecialPrices (data) {
      let authData, result;
       try {
            authData = await Api.getSpecialPrices(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async listrestrictedarea (data) {
      let authData, result;
       try {
            authData = await Api.listrestrictedarea(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async deleterestrictedarea (data) {
      let authData, result;
       try {
            authData = await Api.deleterestrictedarea(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async driverlocation (data) {
      let authData, result;
       try {
            authData = await Api.driverlocation(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
}