import React, { useState, useRef, useEffect } from "react"
import { apiKey} from "../../services/maps"
import { GoogleMap , useJsApiLoader , DrawingManager, Marker, Polygon } from '@react-google-maps/api';
import { Spinner } from "react-bootstrap"
import Swal from 'sweetalert2'
import { server_image } from "../../services/config"
const libraries = ['drawing'];

const MapPicker = ({draggable = false, height= '200px' , isDrawing = false , zoom = 2.5, markers = [], 
                    polygons = [], saveZone}) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        id: 'drawing-manager-example',
        libraries
    })
     // eslint-disable-next-line
    const [map, setMap] = React.useState(null)
    const [coords , setCoords] = useState({ lat: 14.0434647, lng: -87.2000473 })
    const drawing = useRef(null);
    const containerStyle = {
            height: height
     };
    const [myPolygons , setMyPolygons] = useState([])

    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                setCoords({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            });
        }
    },[])

    useEffect(()=>{
        if(isLoaded){
          
            loadMyPolygons()
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[polygons, isLoaded])

   const loadMyPolygons = () =>{
    let areas = []
    for(const key in polygons)
    {
        const polygon = polygons[key];
        const LatLng = [];
        const paths = [];

        for(const j in polygon.coords)
        {
            LatLng.push( { 
                lat: parseFloat(polygon.coords[j].lat),
                lng: parseFloat(polygon.coords[j].long) 
            })
            paths.push( new window.google.maps.LatLng(
                parseFloat(polygon.coords[j].lat),
                parseFloat(polygon.coords[j].long)
            ))
        }
        areas.push({ 
            coords: LatLng, 
            name:polygon.name, 
            polygon: new window.google.maps.Polygon( {paths} )
        })
    }
    setMyPolygons(areas)
   }

   const onLoad = React.useCallback(function callback(map) {
        setMap(map)
        loadMyPolygons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onLoadDrawingManager = drawingManager => {
        // console.log(drawingManager)
    }

    const onPolygonComplete = polygon => {
        Swal.fire({
            title: 'Confirmar Guardar',
            text: 'Ingrese un nombre para el área a guardar',
            icon: 'warning',
            input: 'text',
            confirmButtonText: 'Si, Guardar',
            cancelButtonText: 'No, Cancelar',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: async(name)=>{
                const coordinates = (polygon.getPath().getArray());
                let coords_latlng = []
                for(const i in coordinates){
                    const latlong = coordinates[i];
                    coords_latlng.push({
                        lat: latlong.lat(),
                        lng: latlong.lng()
                    })
                }  
                const validate = await saveZone(name , coords_latlng);
                if(!validate){
                    Swal.showValidationMessage(`Error Inesperado` )
                }
                return validate;
            }
        }).then((result)=>{
            if (result.isConfirmed) {
                Swal.fire(
                    'Guardado!',
                    'Nueva zona guardada',
                    'success'
                )   
            }else{
                //cancelar polygon
                polygon.setMap(null)
            }
        })
    }


    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }
   
    return isLoaded ? (
        <GoogleMap
            id="drawing-manager-example"
            options={{
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
                zoomControl: true,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={containerStyle}
            center={coords}
            zoom={zoom}>
                <Marker
                    position={coords}
                    draggable={draggable}
                    onClick={()=> alert(`Mi Ubicación`)}
                />
                {
                    markers.map((m,index)=>{
                        return(
                            <Marker
                                key={index}
                                position={{lat: m.latitude, lng: m.longitude}}
                                // title={`${m.firstname} ${m.lastname}`}
                                // label={ {
                                //     text: `${m.firstname} ${m.lastname}`,
                                //     color: '#000',
                                //     fontSize: '14px',
                                //     fontWeight: 'bold',
                                //   }}
                                zIndex={100}
                                icon={{
                                    url:`${server_image}${m.icon}`,
                                }}
                                onClick={()=> alert(`${m.firstname} ${m.lastname}`)}
                            />
                        )
                    })
                }
                {
                    isDrawing && <DrawingManager
                        ref={drawing}
                        options={{
                            drawingControl: true,
                            drawingControlOptions: {
                                drawingModes: ["polygon"]
                            },
                            polygonOptions: {
                                fillColor: `#2196F3`,
                                strokeColor: `#2196F3`,
                                fillOpacity: 0.5,
                                strokeWeight: 2,
                                clickable: false,
                                editable: false,
                                draggable: false,
                                zIndex: 1
                            }
                        }}
                        drawingMode={'polygon'}
                        onLoad={onLoadDrawingManager}
                        onPolygonComplete={onPolygonComplete}
                    />
                }
                {
                myPolygons.map((value,index)=>{
                    return(
                        <Polygon
                            key={index}
                            path={value.coords}
                            options={{ fillColor: "red", fillOpacity: 1 }}
                            onClick={()=> alert(value.name)}
                        />
                    );
                })
            }

        <></>
        </GoogleMap>
    ) : <Spinner />
};

export default React.memo(MapPicker)

