import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      try {
         this.mm("dispose");
      } catch (error) {
         
      }
      
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   
   constructor(props){
      super(props)
      this.state = {
         profileVisible:"",
         user:""
    }
 }
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData);
      const profileVisible = this.props.profileVisible;
      this.setState({
         profileVisible,
         user:user.admin_type
      });
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
      }
   render() {
      /// Path
      const path = window.location.pathname;

      /// Active menu
      let pedidos = [
         "pedidos",
      ],
      // eslint-disable-next-line
      login = [ 
         "login",
         "signup"
      ],
      config = [
         "config"
      ],
      dash =[
         ""
      ],
      usuarios = [
         "clientes",
         "conductores",
         "administrador"
      ],
      zonas = [
         "zonas"
      ],
      ubicaciones=[
         "ubicaciones"
      ];
      
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
               <li className={`${dash.includes(path.slice(1)) ? "mm-active" : ""}`} >
                     <Link className="ai-icon" to="" >
                        <i className="flaticon-381-networking"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>
                  <li className={`${ pedidos.includes(path.slice(1)) ? "mm-active" : "" }`} >
                     <Link className="ai-icon" to="pedidos">
                        <i className="flaticon-381-notepad"></i>
                        <span className="nav-text">Pedidos</span>
                     </Link>
                  </li>
                  <li className={`${usuarios.includes(path.slice(1)) ? "mm-active" : ""}`}>
                     <Link to="clientes" className="has-arrow ai-icon">
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Usuarios</span>
                     </Link>
                     <ul >
                        <li>
                           <Link to="clientes">Clientes</Link>
                        </li>
                        <li>
                           <Link to="conductores">Conductores</Link>
                        </li>
                        {this.state.user === "super" && <li>
                           <Link to="administrador">Administrador</Link>
                        </li>}
                     </ul>
                  </li>
                  <li className={`${ zonas.includes(path.slice(1)) ? "mm-active" : "" }`} >
                     <Link  to="zonas" className="ai-icon">
                        <i className="flaticon-381-location-3"></i>
                        <span className="nav-text">Zonas Restringidas</span>
                     </Link>
                  </li>
                  <li className={`${ ubicaciones.includes(path.slice(1)) ? "mm-active" : "" }`} >
                     <Link  to="ubicaciones" className="ai-icon">
                        <i className="flaticon-381-location"></i>
                        <span className="nav-text">Ubicación de Conductores</span>
                     </Link>
                  </li>
                  <li className={`${ config.includes(path.slice(1)) ? "mm-active" : "" }`} >
                     <Link to="config" className="ai-icon" >
                        <i className="flaticon-381-settings-2"></i>
                        <span className="nav-text">Configuración</span>
                     </Link>
                  </li>
               </MM>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
