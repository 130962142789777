import React, { Fragment, useState, useEffect } from "react";
import {
   Table,
   Badge,
   Modal,
   Button,
   Pagination,
   Form,
   Card,
   Col,
   InputGroup,
   FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import Api from "../../modules/api";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import history from "../../history";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Administrador = () => {
   const [loader, setLoader] = useState(true);
   const [completeData, setCompleteData] = useState([]);
   const [newdata , setNewData] = useState([]);
   const [activePag, setActivePag] = useState(0);
   const [modal, setModal] = useState(false);
   const [disabledInputs, setDisabledInputs] = useState(true);
   const [modalNewUser, setModalNewUser] = useState(false);
   const [modalPassword, setModalPassword] = useState(false);
   const [search, setSearch] = useState({search:""});

   const [editProfile, setEditProfilel] = useState(
      {  
         user_id:"",
         firstname:"",
         lastname:"",
         email:"",
      });

   const [ newUser, setNnwUser] = useState(
      {
         firstname:"",
         lastname:"",
         email:"",
         password:"",
         newpassword:"",
      });

   const [editPasswor, setEditPassword] = useState(
      {
         user_id:"",
         password:"",
         newpassword:"",
         confirmnewpassword:"",
      });
  
   useEffect(()=>{
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(user.admin_type === "super"){
         let data = {
            user_id :user.user_id
         };
         Api.adminList(data).then((response)=>{
            if(response && response.data && response.data.users.length>0){
               setCompleteData(response.data.users);
               setNewData((
                  response.data.users.slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
            }else{
               setLoader(false);
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }else{
         history.replace("");
      }
             // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]);

   const disableUser = (item, a, index) => { 
      let FormData = require('form-data');
      let data = new FormData();
      const value = a === "activo" ? "1" : "2";
      data.append('id',item.user_id);
      data.append('user_status', value);
      const estados = a === "activo" ? "activar":"Desactivar";
      const alertestados = a === "activo" ? "activado":"Desactivado";
         Swal.fire({
            text: '¿Seguro que quieres '+estados+' a '+item.firstname+' '+item.lastname+' ?',
            title: 'Alerta',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: a === "activo" ? `Activar`:`Desactivar`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                  Api.updateProfile(data).then((response)=>{
                     console.log(response)
                     if(response && response.result === "success"){
                        const data1 = newdata.slice();
                        data1[index].user_status = value;
                        setNewData(data1);
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     setLoader(false);
                  });
                  Swal.fire('¡Cliente '+alertestados+'  con exito!', '', 'success');
            }else{
                  setDisabledInputs(!disabledInputs);
            }
         })           
   }

   const habndelProfile = (e) => {
      const newPerfil = { ...editProfile };
      newPerfil[e.target.name] = e.target.value;
      setEditProfilel(newPerfil);
   };

   const submitHandler = (e) => { 
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('id',editProfile.user_id);
      data.append('firstname', editProfile.firstname);
      data.append('lastname', editProfile.lastname);
      data.append('email', editProfile.email);

      if(editProfile.firstname === "" || editProfile.lastname === "" || editProfile.email === "" ){
         swal("Alerta", "Todos los campos son necesarios", "error");   
      }else{
         setLoader(true);  
         Swal.fire({
            title: 'Alerta',
            text: '¿Seguro que quieres guardar los cambios?',
            icon:'info',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: `Guardar`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                  Api.updateProfile(data).then((response)=>{
                     // console.log(response);
                     if(response && response.result === "success"){
                        setDisabledInputs(!disabledInputs);
                        setModal(false);
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     console.log(error);
                  });
                  Swal.fire('¡Guardado con exito!', '', 'success');
            }else{
                  setDisabledInputs(!disabledInputs);
            }
         }).finally(()=>{
            setLoader(false);
         })       
      }     
   }

   const habndelNewUser = (e) => {
      const user = { ...newUser };
      user[e.target.name] = e.target.value;
      setNnwUser(user);
   };

   const submitHandlerNewUser = (e) => { 
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('firstname', newUser.firstname);
      data.append('lastname', newUser.lastname);
      data.append('email', newUser.email);
      data.append('password', newUser.password);

      if(newUser.firstname === "" || newUser.lastname === "" || newUser.email === "" || newUser.password === "" || newUser.newpassword === "" ){
         swal("Alerta", "Todos los campos son necesarios", "error");   
      }else{
         if(newUser.newpassword === newUser.password ){
            setLoader(true);  
            Swal.fire({
               title: 'Alerta',
               text: '¿Seguro que quieres agregar a ' +newUser.firstname+' ' +newUser.lastname+' como usuario Administrador?',
               icon: 'info',
               showCancelButton: true,
               cancelButtonText: 'Cancelar',
               confirmButtonText: `Crear`,
            }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                     Api.newAdmin(data).then((response)=>{
                        console.log(response);
                        if(response && response.result === "success"){
                           setModalNewUser(false);
                           Swal.fire('Alerta','¡Usuario creado con exito!', 'info');
                        }else{
                           Swal.fire('Alerta',response.message, 'warning');
                        }
                     }).catch( (error)=> {
                        swal("Alerta",error, "error");
                        console.log(error);
                     }).finally(()=>{
                        setLoader(false);
                     });
               }
            })        
         }else{
            swal("Alerta", "Las contraseña no coinciden", "error");   
         }
      }     
   }

   const habndelPassword = (e) => {
      const newPassword = { ...editPasswor };
      newPassword[e.target.name] = e.target.value;
      setEditPassword(newPassword);
   };

   const submitHandlerPassword = (e) => { 
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('id', editPasswor.user_id);
      data.append('password', editPasswor.newpassword);

      if(editPasswor.newpassword === undefined || editPasswor.newpassword === "" || editPasswor.confirmnewpassword === undefined || editPasswor.confirmnewpassword === "" ){
         swal("Alerta", "Todos los campos son necesarios", "error");   
      }else{
         if(editPasswor.newpassword === editPasswor.confirmnewpassword ){
            Swal.fire({
               title: 'Alerta',
               text: '¿Seguro que quieres cambiar la contraseña?',
               showCancelButton: true,
               cancelButtonText: 'Cancelar',
               confirmButtonText: `Guardar`,
            }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                  Api.updateProfile(data).then((response)=>{
                     // console.log(response);
                     if(response && response.result === "success"){
                        setDisabledInputs(!disabledInputs);
                        setModal(false);
                        setModalPassword(false);
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     console.log(error);
                     setLoader(false);
                  });
                  Swal.fire('¡Guardado con exito!', '', 'success');
               }
            })        
         }else{
            swal("Alerta", "Las contraseña no coinciden", "error");   
         }
      }     
   }

   const updateButton = () => {
      setDisabledInputs(!disabledInputs);
   }

   const modalEnable = (item, a ) => {
      setLoader(true);
      Api.adminGetUser({id: item.user_id}).then((response)=>{
         if(response && response.data){ 
            if(a === "profile"){
               setModal(true);
               setLoader(false);
               setEditProfilel(response.data);
            }else{
               const newedipass = {...response.data , ...{ newpassword:  "", confirmnewpassword:""}};
               setModalPassword(true);
               setEditPassword(newedipass);
               setLoader(false);
            }
         }
      }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
      });
   };

   const modalDisable = (item) => {
      setModal(false); 
      setDisabledInputs(true);
   }

   const [numberSort, setNumberSort] = useState(50);
   const chageSort = (n) => {
      setNumberSort(n);
      setNewData(
         completeData.slice(
                  activePag * n,
                  (activePag+ 1) * n
               )
      )
      setActivePag(0);
   }

   let jobPagination = Array(Math.ceil(completeData.length / numberSort))
   .fill()
   .map((_, i) => i + 1);

   const onClick = (i) => {
      setActivePag(i);
      setNewData(
         completeData.slice(
            i * numberSort,
            (i+ 1) * numberSort
         )
      )
      setActivePag(0);
   }
   
   const stateName = [
      "Activo",
      "Inactivo"
   ]

   const stateBag = [
      "success",
      "danger"
   ]

   const habndelInputSearch = (e) => {
      const newSearch = { ...search };
      newSearch[e.target.name] = e.target.value;
      setSearch(newSearch);
   };

   const handleSearch = () =>{
      setLoader(true);
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
         let data = {
            user_id:user.user_id,
            query:search.search,
         };
         Api.adminList(data).then((response)=>{
            console.log(response)
            if(response && response.data && response.data.users.length>0){
               setCompleteData(response.data.users);
               setNewData((
                  response.data.users.slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
               }else{
               setCompleteData([]);
               setNewData((
                  [].slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
               // swal("Alerta",response.message, "warning");
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }
   }
   
   return (
      <Fragment>
         <div className="col-12">
            <div className="card">
                <Card.Header className="bgl-dark form-head d-flex justify-content-between">
                  <Col>
                     <Form.Label className="ml-4"> </Form.Label>
                     <Col>
                     <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary"
                        table="table-to-xls"
                        filename="administradores"
                        sheet="administradores"
                        buttonText="Exportar"
                     />
                     <Dropdown className="dropdown ml-3 d-inline-block">
                        <Dropdown.Toggle variant="" className="text-black bgl-light">
                           {numberSort}
                           <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                 <rect x="0" y="0" width="24" height="24"></rect>
                                 <circle fill="#000000" cx="12" cy="5" r="2"></circle>
                                 <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                 <circle fill="#000000" cx="12" cy="19" r="2"></circle>
                              </g>
                           </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="">
                           <Dropdown.Item onClick={(n) => chageSort(n=10)}>10</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=20)}>20</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=30)}>30</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=50)}>50</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=100)}>100</Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                     </Col>
                  </Col>
                  <InputGroup className="input-group search-area d-inline-flex bgl-light">
                     <FormControl type="text" className="form-control text-black " name="search" value={search.search} onChange={habndelInputSearch} placeholder="Buscar aqui..." />
                     <InputGroup.Append>
                        <InputGroup.Text className="input-group-text">
                           <Link type="submit"  onClick={()=>handleSearch()}>
                              <i className="flaticon-381-search-2"></i>
                           </Link>
                        </InputGroup.Text>
                     </InputGroup.Append>
                  </InputGroup>
                  <Button onClick={() => setModalNewUser(true)} className="btn btn-primary ml-3">+</Button>
               </Card.Header>
               <div className="card-body pt-0">
                  <div className="default-tab">  
                  {newdata.length !== 0 ?
                     <Table responsive hover id="table-to-xls">
                        <div id="example_wrapper" className="dataTables_wrapper">
                           <table className="display w-100 ">
                              <thead>
                                 <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Estatus</th>
                                    <th>Acción</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {
                                    newdata.map((item,index)=>{
                                       return(
                                          <tr key={index}>
                                                <td>{item.user_id}</td>
                                                <td>
                                                   <span className="w-space-no">
                                                      {item.firstname} {item.lastname}
                                                   </span>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>
                                                   <span className="w-space-no">
                                                   <Badge variant={stateBag[item.user_status -1]}>{stateName[item.user_status -1]}</Badge>
                                                   </span>
                                                </td>
                                                <td>                                                
                                                   <Link onClick={(a) => modalEnable(item, a="profile")} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-eye"></i>
                                                   </Link>                                                
                                                   <Link onClick={(a) => modalEnable(item, a="password")} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-lock"></i>
                                                   </Link>
                                                   {item.user_status === "1" ? <Link onClick={() => disableUser(item ,"",index)} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-ban"></i>
                                                   </Link>:<Link onClick={() => disableUser(item ,"activo", index )} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-check"></i>
                                                   </Link>}
                                                </td>
                                             </tr>
                                       )
                                    })
                                 }
                              </tbody>
                              <tfoot>
                                 <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Estatus</th>
                                    <th>Acción</th>
                                 </tr>
                              </tfoot>
                           </table>
                           <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="dataTables_info">
                                 <p>Mostrando de {activePag * numberSort + 1} a {completeData.length < (activePag + 1) * numberSort ? completeData.length : (activePag + 1) * numberSort}  de {completeData.length} Administradores</p>      
                              </div>
                              <div className="dataTables_paginate paging_simple_numbers">
                                 <Pagination
                                    className="pagination-primary"
                                    size="lg"
                                 >
                                    <li
                                       className="page-item page-indicator "
                                       onClick={() =>
                                          activePag > 0 &&
                                          onClick(activePag -1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-left" />
                                       </Link>
                                    </li>
                                    {jobPagination.map((number, i) => (
                                       <Pagination.Item
                                          className={
                                             activePag === i ? "active" : ""
                                          }
                                          onClick={() => onClick(i)}
                                       >
                                          {number}
                                       </Pagination.Item>
                                    ))}
                                    <li
                                       className="page-item page-indicator"
                                       onClick={() =>
                                          activePag + 1 <
                                             jobPagination.length &&
                                          onClick(activePag + 1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-right" />
                                       </Link>
                                    </li>
                                 </Pagination>
                              </div>
                           </div>
                        </div>
                     </Table>:
                     <div className="align-items-center pt-3 pb-3">
                        <p className="text-center">No hay Conductores</p>
                     </div>}
                  </div>
               </div>
            </div>
         </div>
         <Modal
            className="fade"
            show={modal}
            size= "mg"
         >
            <Modal.Header className="justify-content-between bgl-warning">
               <Modal.Title>Detalle del Administrador ID #{editProfile.user_id}</Modal.Title>
               <Button  className="btn btn-light" onClick={() => updateButton()} >
                  <i className="fa fa-pencil mr-2"></i>
                   Editar
               </Button>
               {/* <Button
                  variant=""
                  className="close"
                  onClick={() => setModal(false)}
               >
                  <span>&times;</span>
               </Button> */}
            </Modal.Header>
            <Modal.Body>
               <div className="basic-form">
                  <form action="" onSubmit={(e) => submitHandler(e)}>
                     <div className="form-row">
                        <div className="form-group col-md-6">
                           <label>Nombre</label>
                           <input
                              type="text"
                              className="form-control"
                              name="firstname"
                              value={editProfile.firstname}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Apellido</label>
                           <input
                              type="text"
                              className="form-control"
                              name="lastname"
                              value={editProfile.lastname}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-12">
                           <label>Email</label>
                           <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={editProfile.email}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                     </div>
                     {disabledInputs ? null:<button type="submit" className="btn btn-primary" onClick={() => submitHandler} >
                        Guardar
                     </button>}
                  </form>
               </div>
            </Modal.Body>
            <Modal.Footer className=" bgl-warning justify-content-end">
               <Button
                  variant="light light"
                  onClick={() => modalDisable()}
               >
                  Cerrar
               </Button>
               
            </Modal.Footer>
         </Modal>
         <Modal
            className="fade"
            show={modalNewUser}
            size="mg"
         >
            <Modal.Header className="bgl-light text-center">
               <Modal.Title className="">Crear Administrador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="basic-form">
                  <form action="" onSubmit={(e) => submitHandlerNewUser(e)}>
                     <div className="form-row">
                        <div className="form-group col-md-6">
                           <label>Nombre</label>
                           <input
                              type="text"
                              className="form-control"
                              name="firstname"
                              placeholder="Nombre"
                              value={newUser.firstname}
                              onChange={habndelNewUser}
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Apellido</label>
                           <input
                              type="text"
                              className="form-control"
                              placeholder="Apellido"
                              name="lastname"
                              value={newUser.lastname}
                              onChange={habndelNewUser}
                           />
                        </div>
                        <div className="form-group col-md-12">
                           <label>Email</label>
                           <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={newUser.email}
                              onChange={habndelNewUser}
                           />
                        </div>
                        <div className="form-group col-md-12">
                           <label>Contraseña</label>
                           <input
                              type="password"
                              className="form-control"
                              placeholder="Contraseña"
                              name="password"
                              value={newUser.password}
                              onChange={habndelNewUser}
                           />
                        </div>
                        <div className="form-group col-md-12">
                           <label>Confirmar contraseña</label>
                           <input
                              type="password"
                              className="form-control"
                              placeholder="Confirmar contraseña"
                              name="newpassword"
                              value={newUser.newpassword}
                              onChange={habndelNewUser}
                           />
                        </div>
                     </div>
                     <button type="submit" className="btn btn-primary" onClick={() => submitHandlerNewUser} >
                        Crear
                     </button>
                  </form>
               </div>
            </Modal.Body>
            <Modal.Footer className=" bgl-light justify-content-end">
               <Button
                  variant="primary"
                  onClick={() => setModalNewUser(!modalNewUser)}
               >
                  Cerrar
               </Button>
               
            </Modal.Footer>
         </Modal>
         <Modal
            className="fade"
            show={modalPassword}
            size="mg"
         >
            <Modal.Header className="bgl-light text-center">
               <Modal.Title className="">Cambiar Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="basic-form">
                  <form action="" onSubmit={(e) => submitHandlerPassword(e)}>
                     <div className="form-row">
                        <div className="form-group col-md-12">
                           <label>Contraseña</label>
                           <input
                              type="password"
                              className="form-control"
                              placeholder="Contraseña"
                              name="newpassword"
                              value={editPasswor.newpassword}
                              onChange={habndelPassword}
                           />
                        </div>
                        <div className="form-group col-md-12">
                           <label>Confirmar contraseña</label>
                           <input
                              type="password"
                              className="form-control"
                              placeholder="Confirmar contraseña"
                              name="confirmnewpassword"
                              value={editPasswor.confirmnewpassword}
                              onChange={habndelPassword}
                           />
                        </div>
                     </div>
                     <button type="submit" className="btn btn-primary" onClick={() => submitHandlerPassword} >
                        Cambiar
                     </button>
                  </form>
               </div>
            </Modal.Body>
            <Modal.Footer className=" bgl-light justify-content-end">
               <Button
                  variant="primary"
                  onClick={() => setModalPassword(!modalPassword)}
               >
                  Cerrar
               </Button>
               
            </Modal.Footer>
         </Modal>
         <Modal
            centered
            show={loader}
            size= "sm"
            animation={false}
            timeout={3000}
            backdrop={false}
            contentClassName={"border-0 bg-transparent"}
         >
            <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
         </Modal>
      </Fragment>
   );  
};

export default Administrador;
