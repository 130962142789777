import { server } from './config'
import client from './client'
const TOKEN_APP = "PIKAPP-10092020";
// eslint-disable-next-line
export default {
	login (data, config = {}) {
		return client.post(`${server}/login`, data, config)
	},
	logout (data, config = {}) {
		return client.post(`${server}/logout`, data, config)
	},
	adminGetUser (data, config = {}) {
		return client.get(`${server}/admingetuser?id=${data.id}&code=${TOKEN_APP}`, data, config)
	},
	updateProfile (data, config = {}) {
		return client.post(`${server}/adminedituser_code`, data, config)
	},
	orderList (data, config = {}) {
		return client.get(`${server}/adminorderlist?user_id=${data.user_id}&status=${data.status}&date1=${data.date_1}&date2=${data.date_2}&query=${data.query}&code=${TOKEN_APP}`, data, config)
	},	
	deleteOrder (data, config = {}) {
		return client.post(`${server}/admindeclineorder`, data, config)
	},
	updateDriver (data, config = {}) {
		return client.post(`${server}/adminupdatedriver`, data, config)
	},
	clientList (data, config = {}) {
		return client.get(`${server}/adminuserlist?user_id=${data.user_id}&query=${data.query}&code=${TOKEN_APP}`, data, config)
	},
	driverList (data, config = {}) {
		return client.get(`${server}/admindriverlist?user_id=${data.user_id}&query=${data.query}&code=${TOKEN_APP}`, data, config)
	},
	adminList (data, config = {}) {
		return client.get(`${server}/adminadminlist?user_id=${data.user_id}&query=${data.query}&code=${TOKEN_APP}`, data, config)
	},
	getVehicle (data, config = {}) {
		return client.get(`${server}/getVehicleList?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	vehicleType (data, config = {}) {
		return client.post(`${server}/updateVehicleType`, data, config)
	},
	newAdmin (data, config = {}) {
		return client.post(`${server}/admincreateadmin`, data, config)
	},
	topDrivers (data, config = {}) {
		return client.get(`${server}/topDrivers?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	totalPedidos (data, config = {}) {
		return client.get(`${server}/totalPedidos?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	mail (data, config = {}) {
		return client.get(`${server}/mailConfig?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	saveMail (data, config = {}) {
		return client.post(`${server}/saveMailConfig`, data, config)
	},
	income (data, config = {}) {
		return client.get(`${server}/totalIncome?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	forgot (data, config = {}) {
		return client.post(`${server}/forgetPassword`, data, config)
	},
	checkForgetTime (data, config = {}) {
		return client.get(`${server}/checkForgetTime?code=${TOKEN_APP}&user_id=${data.userid}`, data, config)
	},
	updatePassword (data, config = {}) {
		return client.post(`${server}/updatePassword`, data, config)
	},
	canceledReason (data, config = {}) {
		return client.get(`${server}/canceledReason?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	getSpecialPriceByVehicle (data, config = {}) {
		return client.post(`${server}/getSpecialPriceByVehicle?id=${data.id}&code=${TOKEN_APP}`, data, config)
	},
	createSpecialPrice (data, config = {}) {
		return client.post(`${server}/createSpecialPrice`, data, config)
	},
	updateSpecialPrice (data, config = {}) {
		return client.post(`${server}/updateSpecialPrice`, data, config)
	},
	updateKmRound (data, config = {}) {
		return client.post(`${server}/updateKmRound`, data, config)
	},
	updateVehiclePrices (data, config = {}) {
		return client.post(`${server}/updateVehiclePrices`, data, config)
	},
	saveRestrictedArea (data, config = {}) {
		return client.post(`${server}/saverestrictedarea`, data, config)
	},
	getSpecialPrices (data, config = {}) {
		return client.get(`${server}/getSpecialPrices?code=${TOKEN_APP}`, data, config)
	},
	listrestrictedarea (data, config = {}) {
		return client.get(`${server}/listrestrictedarea?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
	deleterestrictedarea (data, config = {}) {
		return client.post(`${server}/deleterestrictedarea`, data, config)
	},
	driverlocation (data, config = {}) {
		return client.get(`${server}/admindriverlocation?user_id=${data.user_id}&code=${TOKEN_APP}`, data, config)
	},
}