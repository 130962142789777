import axios from 'axios'
// import history from '../history';
const client = axios.create({
    timeout: 60000,//1 minute
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
})
const TOKEN_APP = "PIKAPP-10092020";

// add interceptor on http client to add token authentication on every request
client.interceptors.request.use((config) => {
	return new Promise((resolve, reject) => { 
    // console.log({config})
        if(config.method !== "get")       
          config.data.append('code', TOKEN_APP);
        resolve(config)		
	})
}, (error) => {
	return Promise.reject(error)
})

/* agregando interceptor si el servidor responde status 401 se regresa al login*/
// response interceptors
/*client.interceptors.response.use((response) => {
    return response
  }, (error) => {
  
    if (error && error.response.status && error.response.status === 401) {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_name');
        history.replace('/login');
    }
    return Promise.reject(error)
  })*/

export default client;
