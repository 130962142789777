import React, { Fragment, useState, useEffect } from "react";
import {
   Row,
   Col,
   Card,
   Tab,
   Nav,
   Modal,
   ListGroup,
} from "react-bootstrap";
import Api from "../../modules/api";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import swal from "sweetalert"; 

const Configuracion = () => {
    const [loader, setLoader] = useState(true);
    // eslint-disable-next-line
    const [completeData, setCompleteData] = useState([]);
    const [disabledInputs, setDisabledInputs] = useState(true);
    const [activeTab, setActiveTab] = useState("");
    const [activeListTab, setActiveListTab] = useState("");
    const [disabledInputsRango, setDdsabledInputsRango] = useState(true);
    const [disabledInputsCosto, setDdsabledInputsCosto] = useState(true);
    const [disabledInputsEmail, setDdsabledInputsEmail] = useState(true);
    const [tabData, setTabData] = useState([ ]);
    const [tabDataRangos, setTabDataRangos] = useState({
        round_1_km: "",
        round_2_km: "",
        round_3_km: "",
        round_4_km: "",
    });
    const [newTarifaEspecial, setNewTarifaEspecial] = useState({
        vehicle_type:"",
        name:"",
        price_km_1: "",
        price_km_2: "",
        price_km_3: "",
        price_km_4: "",
    });
    const [email, setEmail] = useState("");
    const [selectTab, setSelectTab] = useState([]);

    useEffect(()=>{
        const userData = localStorage.getItem('userData');
        const user = JSON.parse(userData); 
        if(userData){
            let data = {
                user_id :user.user_id,
             };
            Api.getVehicle(data).then((response)=>{
            if(response && response.types_vehicles){
                // eslint-disable-next-line
                setCompleteData(response.types_vehicles);
                setTabData(response.types_vehicles);
                const vehicles_enables = response.types_vehicles.filter((item)=> {return item.is_active === "1" })
                if(response.types_vehicles.length>0){
                    setTabDataRangos(response.types_vehicles[0]);
                    setNewTarifaEspecial({'vehicle_type':vehicles_enables[0].type_vehicle_id});
                    setActiveListTab(vehicles_enables[0].name.toLowerCase());
                    setActiveTab("general");
                    let FormData = require('form-data');
                    let newdata = new FormData();
                    newdata.append('id',response.types_vehicles[0].type_vehicle_id);
                    Api.getSpecialPriceByVehicle(newdata).then((response)=>{
                        if(response.result === "success"){
                            setSelectTab(response.data);
                            console.log(response.data,"selectTab")
                            setLoader(false);
                        }else{              
                            Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                            setLoader(false);
                        }
                    }).catch( (error)=> {
                    swal("Alerta",error, "error");
                    console.log(error);
                    setLoader(false);
                    });                
                }
            }else{               
                Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                setLoader(false);
            }
            }).catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
            setLoader(false);
            });
            Api.mail(data).then((response)=>{
                if(response && response.data){
                    setEmail(response.data);
                }
             }).catch( (error)=> {
             swal("Alerta",error, "error");
             console.log(error);
             setLoader(false);
             });
        }
     },[]);

    const habndelRangosCost = (e, i) => {
        const newCost = tabData.slice() ;
        let value = newCost[i];
        let newvalue = { ...value};
        newvalue[e.target.name] = e.target.value;
        newCost[i] = newvalue;
        setTabData(newCost);
    };
    
    const submitHandlerCost = (e, i, data) =>{
        e.preventDefault();
        let FormData = require('form-data');
        let newdata = new FormData();
        newdata.append('id',data.type_vehicle_id);
        newdata.append('round_1_price',data.round_1_price);
        newdata.append('round_2_price',data.round_2_price);
        newdata.append('round_3_price',data.round_3_price);
        newdata.append('round_4_price',data.round_4_price);
        if(data.round_1_price === "" || data.round_2_price === "" || data.round_3_price === "" || data.round_4_price === ""){
            Swal.fire('¡Todos los campos son necesarios!', '', 'warning');
        }else{
            Swal.fire({
                text: '¿Seguro que quieres actualizar los datos?',
                title: 'Alerta',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Guardar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Api.updateVehiclePrices(newdata).then((response)=>{
                        if(response.result === "success"){
                            setDdsabledInputsCosto(!disabledInputsCosto);
                        }else{              
                            Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                            setLoader(false);
                        }
                    }).catch( (error)=> {
                        swal("Alerta",error, "error");
                        console.log(error);
                    });
                    Swal.fire('¡Guardado con exito!', '', 'success');
                }
            })        
        } 
    };
    
    const handlerTarifaEspecial = (e, i) => {
        const newTarifa = selectTab.slice() ;
        let value = newTarifa[i];
        let newvalue = { ...value};
        newvalue[e.target.name] = e.target.value;
        newTarifa[i] = newvalue;
        setSelectTab(newTarifa);
    };

    const submitHandlerTarifaEspecial = (e, tarifas) => {
        // console.log(tarifas.type_vehicle_id)
        // console.log(tarifas.name)
        // console.log(tarifas.round_1_price)
        e.preventDefault();
        let FormData = require('form-data');
        let newdata = new FormData();
        newdata.append('id',tarifas.special_price_id);
        newdata.append('vehicle_type',tarifas.type_vehicle_id);
        newdata.append('name',tarifas.name);
        newdata.append('price_km_1',tarifas.round_1_price);
        newdata.append('price_km_2',tarifas.round_2_price);
        newdata.append('price_km_3',tarifas.round_3_price);
        newdata.append('price_km_4',tarifas.round_4_price);
        if(tarifas.name === "" || tarifas.round_1_price === "" || tarifas.round_2_price === "" || tarifas.round_3_price === "" || tarifas.round_4_price === ""){
            Swal.fire('¡Todos los campos son necesarios!', '', 'warning');
        }else{
            Swal.fire({
                text: '¿Seguro que quieres actualizar los datos?',
                title: 'Alerta',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Guardar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Api.updateSpecialPrice(newdata).then((response)=>{
                        if(response && response.result === "success"){
                            setDdsabledInputsCosto(!disabledInputsCosto)
                        }else{              
                            Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                            setLoader(false);
                        }
                    }).catch( (error)=> {
                        swal("Alerta",error, "error");
                        console.log(error);
                    });
                    Swal.fire('¡Guardado con exito!', '', 'success');
                }
            })        
        } 
    }

    const  handlerNewTarifaEspecial = (e) => {
        let newTarifaE = { ...newTarifaEspecial};
        newTarifaE[e.target.name] = e.target.value;
        setNewTarifaEspecial(newTarifaE);
    };

    const submitHandlerNewTarifaEspecial = (e) => {
        setLoader(true);
        e.preventDefault();
        let FormData = require('form-data');
        let newdata = new FormData();
        newdata.append('vehicle_type',newTarifaEspecial.vehicle_type);
        newdata.append('name',newTarifaEspecial.name);
        newdata.append('price_km_1',newTarifaEspecial.price_km_1);
        newdata.append('price_km_2',newTarifaEspecial.price_km_2);
        newdata.append('price_km_3',newTarifaEspecial.price_km_3);
        newdata.append('price_km_4',newTarifaEspecial.price_km_4);
        if(newTarifaEspecial.name === "" || newTarifaEspecial.price_km_1 === "" || newTarifaEspecial.price_km_2 === "" || newTarifaEspecial.price_km_3 === "" || newTarifaEspecial.price_km_4 === ""){
            Swal.fire('¡Todos los campos son necesarios!', '', 'warning');
            setLoader(false);
        }else{
            Swal.fire({
                text: '¿Seguro que quieres una Tarifa Especial?',
                title: 'Alerta',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Crear`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Api.createSpecialPrice(newdata).then((response)=>{
                        if(response && response.result === "success"){
                            Swal.fire('¡Agregado con exito!', '', 'success');
                            setLoader(false);
                            setNewTarifaEspecial({
                                vehicle_type:"",
                                name:"",
                                price_km_1: "",
                                price_km_2: "",
                                price_km_3: "",
                                price_km_4: "",
                            })
                                 window.location.reload();
                        }else{              
                            Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                            setLoader(false);
                        }
                    }).catch( (error)=> {
                        swal("Alerta",error, "error");
                        console.log(error);
                    });
                }
            })  
            setLoader(false);      
        } 
    }

    const submitPreventEmail = (e) =>{
        const userData = localStorage.getItem('userData');
        const user = JSON.parse(userData); 
        if(userData){
            let FormData = require('form-data');
            let newdata = new FormData();
            newdata.append('user_id', user.user_id);
            newdata.append('emails', email);
            Swal.fire({
                title: 'Alerta',
                text: '¿Seguro que quieres actualizar los email?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Guardar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Api.saveMail(newdata).then((response)=>{
                        if(response === "false"){
                            Swal.fire('¡Lo siento!', 'los datos fueron no cumplen con los parametros', 'warning');
                        }else{
                            setDdsabledInputsEmail(!disabledInputsEmail);
                            Swal.fire({
                                title: '',
                                text: '¡Guardado con exito!',
                                icon: 'success',
                                confirmButtonText: `Ok`,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setDdsabledInputsEmail(!disabledInputsEmail);
                                }else{              
                                    Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                                    setLoader(false);
                                }
                            });
                        }
                    }).catch( (error)=> {
                        swal("Alerta",error, "error");
                        console.log(error);
                    });
                }
            }) 
        }       
    };

    const habndelRangoskm = (e) => {
        const newKm = { ...tabDataRangos };
        newKm[e.target.name] = e.target.value;
        setTabDataRangos(newKm);
    };
    
    const submitHandlerkm = (e) =>{
        e.preventDefault();
        let FormData = require('form-data');
        let newdata = new FormData();
        if(tabDataRangos.round_1_km === "" || tabDataRangos.round_2_km === "" || tabDataRangos.round_3_km === "" || tabDataRangos.round_4_km === ""){
            Swal.fire('¡Todos los campos son necesarios!', '', 'warning');
        }else{
            Swal.fire({
                title: 'Alerta',
                text: '¿Seguro que quieres actualizar los datos?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: `Guardar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                        newdata.append('round_1_km',tabDataRangos.round_1_km);
                        newdata.append('round_2_km',tabDataRangos.round_2_km);
                        newdata.append('round_3_km',tabDataRangos.round_3_km);
                        newdata.append('round_4_km',tabDataRangos.round_4_km);
                        Api.updateKmRound(newdata).then((response)=>{
                            if(response && response.result === "success"){
                                setDdsabledInputsRango(!disabledInputsRango);
                            }else{              
                                Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                                setLoader(false);
                            }
                        }).catch( (error)=> {
                            swal("Alerta",error, "error");
                            console.log(error);
                        });
                        Swal.fire('¡Guardado con exito!', '', 'success');
                    // }
                }
            })       
        }  
    };

    const updateCheck = (item, index) =>{
        const newtabdata =  tabData.slice();
        let FormData = require('form-data');
        let data = new FormData();
        const value = item.is_active === "1" ? "0" : "1";
        data.append('id',item.type_vehicle_id);
        data.append('is_active',value);
        Swal.fire({
            title: 'Alerta',
            icon: 'info',
            text: item.is_active === "1" ? '¿Seguro que quieres deshabilitar '+item.name+'?':'Seguro que quieres habilitar '+item.name+'?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: item.is_active === "1" ? `Deshabilitar` :`Habilitar`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                  Api.vehicleType(data).then((response)=>{
                     if(response && response.result === "success"){
                        newtabdata[index].is_active = value;
                        setTabData(newtabdata);
                        if(value==="1")
                            setActiveTab(item.name.toLowerCase())
                        else{
                            const vehicles_enables = newtabdata.filter((item)=> {return item.is_active === "1" })
                            if(vehicles_enables.length>0){
                                setActiveTab(vehicles_enables[0].name.toLowerCase());
                                setLoader(false);
                            }else{              
                                Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                                setLoader(false);
                            }
                            
                        }                        
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     console.log(error);
                  });
                  Swal.fire('¡Guardado con exito!', '', 'success');
            }else{
                  setDisabledInputs(!disabledInputs);
            }
        })  
    };

    const changeTab = (data) =>{
        setActiveTab(data.special_price_id);
        setDdsabledInputsRango(true);
        setDdsabledInputsCosto(true);
    };
    
    const changeListTab = (data)=>{
        setNewTarifaEspecial({'vehicle_type':data.type_vehicle_id});
        setActiveListTab(data.name.toLowerCase());
        setDdsabledInputsCosto(true);
        setDdsabledInputsRango(true);
        let FormData = require('form-data');
        let newdata = new FormData();
        newdata.append('id',data.type_vehicle_id);
        Api.getSpecialPriceByVehicle(newdata).then((response)=>{
            if(response.result === "success"){
                setSelectTab(response.data);
            }else{              
                Swal.fire('Alert','¡Ocurrio un error inesperado!',  'error');
                setLoader(false);
            }
        }).catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
            setLoader(false);
        });                
    }

    return (
        <Fragment>
            <Col xl={12} lg={12} md={12} xxl={12}>
                <Row>
                    <Col xl={12} lg={12} sm={12} md={12} xxl={12}>
                        <Card>
                            <div className="card-header border-0">
                                <div>
                                    <h4 className="text-black font-w600 wspace-no">Precios Por Vehículo en LPS.</h4>
                                    <p className="fs-13 mb-0">Seleccione el tipo de vehículo que desea visualizar sus precios. <br/>Puede crear nuevos precios del vehículo seleccionado haciendo clic en "+"</p>
                                    <p className="fs-13 mb-0">Para editar precio general haga clic en el boton "editar" y luego guardar.</p>
                                </div>
                                <button className="btn btn-light  mr-2 mb-2"  onClick={(i) => setDdsabledInputsCosto(!disabledInputsCosto)} > {disabledInputsCosto ? "Editar" : "Cancelar"} </button>
                            </div>
                            <Card.Body className="mb-0 mt-0">
                                <div className="basic-list-group">
                                    <Row>
                                        <Tab.Container activeKey={activeListTab} defaultActiveKey="carro">
                                            <Col sm="12" lg="4" xl="3">
                                                <ListGroup className="mb-4" id="list-tab">
                                                    {tabData.map((data, i) => (
                                                        <ListGroup.Item as="li" key={i} action eventKey={data.name.toLowerCase()} onClick={() => changeListTab(data)}>
                                                            {data.name}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </Col>
                                            <Col sm="12" lg="8" xl="9">
                                                <Tab.Content>
                                                    {tabData.map((data, i) => (
                                                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                            {/* contenido de listgroup */}
                                                            <Tab.Container activeKey={activeTab}>
                                                                <Nav as="ul" className="nav-tabs">
                                                                    <Nav.Link as="li" eventKey={"general"} onClick={() => changeTab({special_price_id:"general"})}>
                                                                        General
                                                                    </Nav.Link>
                                                                    {selectTab.map((tarifas, j) => (
                                                                        <Nav.Item as="li" key={j}>
                                                                            <Nav.Link eventKey={tarifas.special_price_id.toLowerCase()} onClick={() => changeTab(tarifas.special_price_id)}>
                                                                                {tarifas.name}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    ))}
                                                                    <Nav.Link as="li" eventKey={"add"} onClick={() => changeTab({special_price_id:"add"})}>
                                                                        +
                                                                    </Nav.Link>
                                                                </Nav>
                                                                <Tab.Content className="pt-2">
                                                                    <Tab.Pane eventKey={"general"} >
                                                                        <div className="basic-form">
                                                                            <form action="" onSubmit={(e) => submitHandlerCost(e, i, data)}>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 1</label>
                                                                                        <div className="input-group ">                                                                      
                                                                                            <input type="text" className="form-control" name="round_1_price" onChange={(e) => habndelRangosCost(e, i)} value={data.round_1_price} disabled={disabledInputsCosto}/>
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>      
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 2</label>
                                                                                        <div className="input-group ">                                                 
                                                                                            <input type="text" className="form-control" name="round_2_price" onChange={(e) => habndelRangosCost(e, i)} value={data.round_2_price} disabled={disabledInputsCosto}/>
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>      
                                                                                        </div>                           
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 3</label>
                                                                                        <div className="input-group ">                     
                                                                                            <input type="text" className="form-control" name="round_3_price" onChange={(e) => habndelRangosCost(e, i)} value={data.round_3_price} disabled={disabledInputsCosto}/>
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>  
                                                                                        </div>                                                                                                            
                                                                                    </div>
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 4</label>
                                                                                        <div className="input-group ">                              
                                                                                            <input type="text" className="form-control" name="round_4_price" onChange={(e) => habndelRangosCost(e, i)} value={data.round_4_price} disabled={disabledInputsCosto}/>
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>    
                                                                                        </div>                                                                                                
                                                                                    </div>
                                                                                </div>
                                                                                <Col className="d-flex justify-content-end">
                                                                                    {disabledInputsCosto ? null:<button type="submit" className="btn btn-primary mt-1"  onClick={(e) => submitHandlerCost(e, i, data)}>Guardar</button>}
                                                                                </Col>
                                                                            </form>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                                {selectTab.map((tarifas,i)=>(
                                                                    <Tab.Content className="pt-0">
                                                                        <Tab.Pane eventKey={tarifas.special_price_id.toLocaleLowerCase()} >
                                                                            <div className="basic-form">
                                                                                <form action="" onSubmit={(e) => submitHandlerTarifaEspecial(e, tarifas)}>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="mb-1 col-md-6">  
                                                                                            <label className="mt-1">Rango 1</label>
                                                                                            <div className="input-group ">                                                           
                                                                                                <input type="text" className="form-control" name="round_1_price" onChange={(e) => handlerTarifaEspecial(e, i)} value={tarifas.round_1_price} disabled={disabledInputsCosto}/>
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text">LPS.</span>
                                                                                                </div>         
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mb-1 col-md-6">  
                                                                                            <label className="mt-1">Rango 2</label>
                                                                                            <div className="input-group ">                                                                           
                                                                                                <input type="text" className="form-control" name="round_2_price" onChange={(e) => handlerTarifaEspecial(e, i)} value={tarifas.round_2_price} disabled={disabledInputsCosto}/>
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text">LPS.</span>
                                                                                                </div>   
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="mb-1 col-md-6">  
                                                                                            <label className="mt-1">Rango 3</label>
                                                                                            <div className="input-group ">                                                                
                                                                                                <input type="text" className="form-control" name="round_3_price" onChange={(e) => handlerTarifaEspecial(e, i)} value={tarifas.round_3_price} disabled={disabledInputsCosto}/>
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text">LPS.</span>
                                                                                                </div>     
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mb-1 col-md-6">  
                                                                                            <label className="mt-1">Rango 4</label>
                                                                                            <div className="input-group ">                                                                                 
                                                                                                <input type="text" className="form-control" name="round_4_price" onChange={(e) => handlerTarifaEspecial(e, i)} value={tarifas.round_4_price} disabled={disabledInputsCosto}/>
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text">LPS.</span>
                                                                                                </div>    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Col className="d-flex justify-content-end">
                                                                                        {disabledInputsCosto ? null:<button type="submit" className="btn btn-primary mt-1"  onClick={(e) => submitHandlerTarifaEspecial(e, tarifas)}>Guardar</button>}
                                                                                    </Col>
                                                                                </form>
                                                                            </div>        
                                                                        </Tab.Pane>
                                                                    </Tab.Content>
                                                                ))}
                                                                <Tab.Content className="pt-2">
                                                                    <Tab.Pane eventKey={"add"} >
                                                                        <div className="basic-form">
                                                                            <form action="" onSubmit={(e) => submitHandlerNewTarifaEspecial(e)}>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="mb-1 col-md-6 d-flex align-items-center">  
                                                                                        <label className="mt-1 mr-2 center">Nombre</label>                                                                  
                                                                                        <div className="input-group">    
                                                                                            <input type="text" placeholder="Nombre" className="form-control" name="name" onChange={(e) => handlerNewTarifaEspecial(e, i)} value={newTarifaEspecial.name}/>   
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="">  
                                                                                        <button type="submit" className="btn btn-primary mr-3"  onClick={(e) => submitHandlerNewTarifaEspecial (e)}>Guardar</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 1</label>
                                                                                        <div className="input-group">                                                                      
                                                                                            <input type="text" placeholder="0.0" className="form-control" name="price_km_1" onChange={(e) => handlerNewTarifaEspecial(e, i)} value={newTarifaEspecial.price_km_1} />
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>      
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 2</label>
                                                                                        <div className="input-group ">                                                 
                                                                                            <input type="text" placeholder="0.0" className="form-control" name="price_km_2" onChange={(e) => handlerNewTarifaEspecial(e)} value={newTarifaEspecial.price_km_2} />
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>      
                                                                                        </div>                           
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 3</label>
                                                                                        <div className="input-group ">                     
                                                                                            <input type="text" placeholder="0.0" className="form-control" name="price_km_3" onChange={(e) => handlerNewTarifaEspecial(e)} value={newTarifaEspecial.price_km_3} />
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>  
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-1 col-md-6">  
                                                                                        <label className="mt-1">Rango 4</label>
                                                                                        <div className="input-group ">                              
                                                                                            <input type="text" placeholder="0.0" className="form-control" name="price_km_4" onChange={(e) => handlerNewTarifaEspecial(e)} value={newTarifaEspecial.price_km_4} />
                                                                                            <div className="input-group-append">
                                                                                                <span className="input-group-text">LPS.</span>
                                                                                            </div>    
                                                                                        </div>                                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                                {/* fin contenido de listgroup */}
                                                            </Tab.Container>
                                                        </Tab.Pane>
                                                    ))}
                                                </Tab.Content>
                                            </Col>
                                        </Tab.Container>
                                    </Row>
                                </div>
                            </Card.Body>
                            {loader ? null:
                            <div className="card-body pt-0">
                                <div className="default-tab">
                                    
                                </div>
                            </div>}
                        </Card>
                    </Col>
                    <Col xl={4} sm={12} md={4} xxl={4}>
                        <Card>
                            <div className="card-header ml-3 border-0">
                                <div>
                                    <h4 className="text-black font-w600 wspace-no">Vehículos Activos</h4>
                                    <p className="fs-13 mb-0">Seleccione los tipos de vehículos que están activos para los clientes</p>
                                </div>
                            </div>
                            {loader ? null:
                                <div>
                                {tabData.map((data, i) => {
                                    return (
                                        <div className="card-body pt-0" key={i}>
                                            <div className="col-6 col-sm-6" key={i}>
                                                <div className="custom-control custom-checkbox" key={i}>
                                                    <input 
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        key={i}
                                                        id={"customCheckBox"+i}
                                                        checked={data.is_active === "1"}
                                                        onChange={() => updateCheck(data, i)}
                                                    />
                                                    <label key={i}
                                                        className="custom-control-label"
                                                        htmlFor={"customCheckBox"+i}
                                                    >
                                                        {data.name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                )}
                            </div>}
                        </Card>
                    </Col>
                    <Col xl={8} sm={12} md={8} xxl={8}>
                        <Card>
                            <div className="card-header border-0">
                                <div>
                                    <h4 className="text-black font-w600 ml-3">Rangos de Pedidos en Km.</h4>
                                    <p className="fs-13 ml-3 mb-0">Para editar los rangos haga clic en el boton "editar" y luego guardar.</p>
                                </div>
                                <button className="btn btn-light  mr-2 mb-2"  onClick={(i) => setDdsabledInputsRango(!disabledInputsRango)} > {disabledInputsRango ? "Editar" : "Cancelar"} </button>
                            </div>
                            {loader ? null:
                            <div className="card-body pt-0">
                                <div className="default-tab">
                                    <div className="basic-form">
                                        <form action="" onSubmit={(e) => submitHandlerkm(e)}>
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mb-1 col-md-6">  
                                                        <label className="mt-1">Rango 1</label>   
                                                        <div className="input-group mb-1 ">                                             
                                                            <input type="text" className="form-control" name="round_1_km" onChange={habndelRangoskm} value={tabDataRangos.round_1_km} disabled={disabledInputsRango}/> 
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">Km.</span>
                                                            </div>                        
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 col-md-6">  
                                                        <label className="mt-1">Rango 2</label>   
                                                        <div className="input-group mb-1 ">                                                                               
                                                            <input type="text" className="form-control" name="round_2_km" onChange={habndelRangoskm} value={tabDataRangos.round_2_km} disabled={disabledInputsRango}/> 
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">Km.</span>
                                                            </div>                
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mb-1 col-md-6">  
                                                        <label className="mt-1">Rango 3</label>   
                                                        <div className="input-group mb-1 ">                                                                   
                                                            <input type="text" className="form-control" name="round_3_km" onChange={habndelRangoskm} value={tabDataRangos.round_3_km} disabled={disabledInputsRango}/> 
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">Km.</span>
                                                            </div>                
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 col-md-6">  
                                                        <label className="mt-1">Rango 4</label>   
                                                        <div className="input-group mb-1 ">                                                             
                                                            <input type="text" className="form-control" name="round_4_km" onChange={habndelRangoskm} value={tabDataRangos.round_4_km} disabled={disabledInputsRango}/>  
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">Km.</span>
                                                            </div>                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Col className="d-flex justify-content-end">
                                                {disabledInputsRango ? null:<button type="submit" className="btn btn-primary ml-3 mt-1"  onClick={() => submitHandlerkm}>Guardar</button>}
                                            </Col>
                                        </form>
                                    </div>
                                </div>
                            </div>}
                        </Card>
                    </Col>
                    <Col xl={12} md={12} lg={12} xxl={12}>
                        <Card>
                            <div className="card-header border-0">
                                <div>
                                    <h4 className="text-black font-w600 wspace-no ml-3">Notificaciones</h4>
                                    <p className="fs-13 ml-3 mb-0">Escriba los correos electrónicos separados por una coma (,) donde desea recibir las notificaciones.</p>
                                </div>
                                    {disabledInputsEmail === false &&
                                        <button
                                        type="submit"
                                        className="btn btn-light mr-3"
                                        onClick={(e) => setDdsabledInputsEmail(!disabledInputsEmail)}
                                        >
                                        Cancelar
                                    </button>}
                            </div>
                            <Card.Body className="pt-0 d-flex justify-content-between">
                                <Col xl={12} md={12} lg={12} xxl={12}>
                                    {/* <form onSubmit={(e) => e.submitPreventEmail(e)}> */}
                                        {loader ? null:
                                        <div className="input-group">
                                            <input type="text" className="form-control" name="Email" onChange={(e) => setEmail(e.target.value) } value={email} disabled={disabledInputsEmail}/>
                                            <div className="input-group-append">
                                            {disabledInputsEmail ?
                                                <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={(e) => setDdsabledInputsEmail(!disabledInputsEmail)}
                                                >
                                                Editar
                                            </button>:
                                            <button
                                                type="submit"S
                                                className="btn btn-primary"
                                                onClick={(e) => submitPreventEmail(e)}
                                                >
                                                Guardar
                                            </button>}
                                            </div>
                                        </div>}
                                    {/* </form> */}
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign:"right"}}>
                        <p>v.b202111.573G</p>
                        {/* <p>v.b202110.D25NP7YMFY0T</p> */}
                    </Col>
                </Row>
            </Col>
         <Modal
            centered
            show={loader}
            size= "sm"
            animation={false}
            timeout={3000}
            backdrop={false}
            contentClassName={"border-0 bg-transparent"}
         >
            <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
         </Modal>
        </Fragment>
    );  
};

export default Configuracion;
