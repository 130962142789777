import React, { useState , useEffect} from "react";
import profile from "../../images/pik_principal.png";
import swal from "sweetalert";
import Api from "../../modules/api"
import { Button , Fade } from "react-bootstrap"
import ResetPassword from "./ResetPassword";

const Login = ({setToken}) => {
   const [loginData, setLoginData] = useState({email:"", password:""});
   const [isForgotVisible , setForgotVisible] = useState(false)
   const [isLoading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [isResetVisilbe, setResetVisible] = useState(false)
   const [userToReset, setUserToReset ] = useState("")

   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };
  
   const submitHandler = (e) => {
      e.preventDefault();

      let FormData = require('form-data');
      let data = new FormData();
      data.append('email', loginData.email);
      data.append('password', loginData.password);

      if(loginData.email === "" || loginData.password ===""){
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
         Api.login(data).then((response)=> {
            // console.log(response)
            if(response)
            {
               if(response.result === "failure"){
                  swal("Alerta",response.message, "error");
               }else{
                  setSession(response.data)
                  //history.replace('/');
               }
            }else{
               swal("Alerta","erro inesperado")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         });
      }
   };

   const setSession = (authResult) => {
      const userData = {
         email : authResult.email,
         name: authResult.name,
         user_id: authResult.user_id,
         admin_type: authResult.admin_type,
         profile_image: authResult.profile_image
      }
      setToken(userData);
   };


   useEffect(()=>{
      if(window.location.pathname === "/reset-password")
      {
         const url_string = window.location.href;
         const url = new URL(url_string);
         const token = url.searchParams.get("token");
         setUserToReset(token);
         setResetVisible(true);
      }
      setOpen(true)
   },[])

   const forgotPassoword = (e) =>{
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('email', loginData.email);

      if(loginData.email === "" ){
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
         setLoading(true);
         Api.forgot(data)
            .then((response)=> {
               if(response)
               {
                  if(response.result === "false"){
                     swal("Alerta",response.message, "error");
                  }else{
                     swal("Alerta",response.message, "success");
                     setForgotVisible(!isForgotVisible);
                     setOpen(!open);
                  }
               }else{
                  swal("Alerta","error inesperado","error")
               }
            })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         }).finally(()=>{
            setLoading(false);
         });
      }
   }

   const exit = () =>{
      setUserToReset("");
      setResetVisible(false);
   }

   if(isResetVisilbe){
      return(
         <ResetPassword token={userToReset} exit={exit}/>
      )
   }

   return (
      <div className="row justify-content-center align-items-center h-80">
         <div className="col-md-5 col-xl-5 col-lg-5">
            <div className="form-group">
               <div className="profile-photo text-center">
                  <img src={profile}	className="img-fluid" alt="profile"	/>
               </div>     
            </div>  
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
{             !isForgotVisible &&   
                  <Fade in={ open} timeout={50000} 
                        onExited={()=> setForgotVisible(!isForgotVisible)}
                       
                        >
                        <div className="auth-form">
                           <h4 className="text-center mb-4 text-primary"> Inicie sesión en su cuenta  </h4>
                           <form  action=""  onSubmit={(e) => submitHandler(e)}  >
                              <div className="form-group">    
                                 <label className="mb-1 text-primary">  <strong>Correo electrónico</strong> </label>
                                 <input type="email" className="form-control text-primary" name="email" value={loginData.email}  onChange={handleBlur}   />
                              </div>
                                 <div className="form-group">
                                    <label className="mb-1 text-primary"> <strong>Contraseña</strong>  </label>
                                    <input type="password" className="form-control text-primary" name="password" value={loginData.password}  onChange={handleBlur}  />
                                 </div>
                              <div className="text-center">
                                 <Button  
                                    type="submit"  
                                    className="btn btn-primary btn-block"  
                                    disabled={isLoading}
                                    onClick={() => submitHandler} > 
                                       {!isLoading ? "Iniciar sesión" : "Espere ..."} 
                                 </Button>
                              </div>
                           </form>
                           <div className="new-account mt-3">
                              <p className="text-center">   
                                 <Button 
                                    variant="link" 
                                    className="text-secondary" 
                                    onClick={()=>  setOpen(false)}>
                                       {"¿Se te olvidó tu contraseña? " }
                                 </Button>  
                              </p>
                           </div>
                        </div>
                     </Fade>}
                     { 
                      isForgotVisible &&   
                     //  <Fade in={open2} 
                     //    timeout={5000}  
                     //    onExited={()=> setForgotVisible(!isForgotVisible)}>
                        <div className="auth-form">
                           {<h4 className="text-center mb-4 text-primary"> Restablecer Contraseña  </h4>}
                           <form  action=""  onSubmit={(e) => forgotPassoword(e)}  >
                              <div className="form-group">    
                                 <label className="mb-1 text-primary">  <strong>Correo electrónico</strong> </label>
                                 <input type="email" className="form-control text-primary" name="email" value={loginData.email}  onChange={handleBlur}   />
                              </div>
                              <div className="text-center">
                                 <Button  
                                    type="submit"  
                                    className="btn btn-primary btn-block"  
                                    disabled={isLoading}
                                    onClick={() => forgotPassoword} > 
                                       {!isLoading ? "Resetear Contraseña" : "Enviando ..." } 
                                 </Button>
                              </div>
                           </form>
                           <div className="new-account mt-3">
                              <p className="text-center">   
                                 <Button 
                                    variant="link" 
                                    className="text-secondary" 
                                    onClick={()=>  {setForgotVisible(!isForgotVisible);setOpen(!open);}}>
                                       {"¿Tienes Cuenta? Inicia Sesión"}
                                 </Button>  
                              </p>
                           </div>
                        </div>
                     // </Fade>
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
