import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    Card,
    Col,
    Form,
    Modal
 } from "react-bootstrap";
import MapPicker  from '../components/MapPicker';
import Api from "../../modules/api";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import swal from "sweetalert"; 

const Zonas = ()  =>{
    const [loader, setLoader] = useState(true);
    const [zonas, setZonas] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
     // eslint-disable-next-line
    const mapRef = useRef();

    useEffect(()=>{
        const userData = localStorage.getItem('userData');
        const user = JSON.parse(userData); 
        if(userData){
            let data = {
                user_id :user.user_id,
             };
            Api.listrestrictedarea(data).then((response)=>{
                if(response.result === true){
                    // console.log(response.data);
                    setZonas(response.data);
                }
            }).catch( (error)=> {
                swal("Alerta",error, "error");
                console.log(error);
            }).finally(()=>{
                setLoader(false);
            });
        }
     },[]);

    const selectZone = (item) =>{
        setSelectedZone(item.target.value);
    }

    const deleteZone = () =>{
        if(selectedZone === null || selectedZone === "Seleccione una zona"){
            Swal.fire({title:'¡Alerta!', text:'Debe seleccionar una zona', icon:'warning'});
        }else{
            let FormData = require('form-data');
            let data = new FormData();
            data.append('id_restricted_area',selectedZone);
            Swal.fire({
                title: "Alerta",
                text:'¿Seguro que quieres eliminar la zona seleccionada?',
                icon:"warning",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: "Eliminar",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setLoader(true);
                    Api.deleterestrictedarea(data).then((response)=>{
                        const index = zonas.findIndex((item)=>item.id === selectedZone)
                        if(index>=0)
                        {
                            const newzones = zonas.slice();
                            newzones.splice(index, 1)
                           // mapRef.current.deletePolygon(index)
                            setZonas(newzones);
                            setSelectedZone(-1);
                        }
                        setLoader(false);
                    }).catch( (error)=> {
                    swal("Alerta",error, "error");
                    console.log(error);
                    }); 
                    Swal.fire({text:'¡Zona eliminada con exito! Refresce Mapa',title: '', icon:'success'});
                }
            })          
        }    
    }

    const saveZone = async(name , coords_latlng) =>{
        let FormData = require('form-data');
        let data = new FormData();
        data.append('name',name);
        data.append('coords',JSON.stringify({coords: coords_latlng}));   
        const userData = localStorage.getItem('userData');
        const user = JSON.parse(userData); 
        data.append('user_id',user.user_id);
        const response = await  Api.saveRestrictedArea(data);
        if(response && response.result)
        {
            const newzones = zonas.slice();
            newzones.push({
                id:response.id,
                name
            })
            setZonas(newzones);
            return true;
        }else return false;
    }

    return (
        <Fragment>
            <Col sm={12} lg={12} xl={12}>   
                <Card className="">
                    {/* <Card.Header className="border-0 mb-0">
                        <p></p>
                    </Card.Header> */}
                    <Card.Body>
                        <div>
                            <h4 className="text-black font-w600 wspace-no">Seleccione los puntos a restringir</h4>
                            <p className="fs-13 mb-0">Para dibujar la forma que desee, haga clic en un punto de inicio en el mapa y arrastre.</p>
                            <p className="fs-13 mb-0">Haga clic en un punto final. Luego, escriba una descripción para la nueva zona a crear</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <p>Zonas creadas</p>
                                <svg height="20" width="20" className="mt-2 ml-1">
                                    <rect width="30" height="10" stroke="red" stroke-width="3" fill="red" />
                                </svg>                     
                                <p className="ml-3">Zonas nuevas</p>
                                <svg height="20" width="20" className="mt-2 ml-1">
                                    <rect width="30" height="10" stroke="#2196F3" stroke-width="3" fill="#2196F3" />
                                </svg>                     
                            </div>
                            
                        </div>
                        <MapPicker  height={"400px"} zoom={15} isDrawing polygons={zonas} saveZone={(name , coords)=> saveZone(name , coords)}/>
                        <label>Seleccione una zona para eliminarla</label>
                        <div className="form-group col-md-6 ml-0 pl-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <Form.Control as="select" value={selectedZone} onChange={(item)=>selectZone(item)}>
                                    <option key={-1} value={null}>Seleccione una zona</option>
                                {
                                    zonas.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                                </Form.Control>
                            <button className="btn btn-primary  ml-2" onClick={() => deleteZone()}>Eliminar</button>
                            </div>

                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Modal
                centered
                show={loader}
                size= "sm"
                animation={false}
                timeout={3000}
                backdrop={false}
                contentClassName={"border-0 bg-transparent"}
            >
                <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
            </Modal>
        </Fragment>
    );
}
export default (Zonas)