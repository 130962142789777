import React, { Fragment, useState, useEffect } from "react";
import {
   Table,
   Badge,
   Modal,
   Button,
   Pagination,
   Form,
   Card,
   Col,
   InputGroup,
   FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import Api from "../../modules/api";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import swal from "sweetalert"; 
import { server_image } from "../../services/config"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Conductores = () => {
   const [loader, setLoader] = useState(true);
   const [completeData, setCompleteData] = useState([]);
   const [newdata , setNewData] = useState([])
   const [activePag, setActivePag] = useState(0)
   const [disabledInputs, setDisabledInputs] = useState(true);
   const [modal, setModal] = useState(false);
   const [modalType] = useState(false);
   const profile_image = "52e164499740b60ce6e0fdaefd2c9372.jpg";
   const [search, setSearch] = useState({search:""});
   const [editProfile, setEditProfilel] = useState(
      {
         user_id:"",
         company_name:"",
         firstname:"",
         lastname:"",
         email:"",
         country_code:"",
         phone:"",
         house_no:"",
         street_name:"",
         street_suf:"",
         suburb:"",
         state:"",
         postcode:"",
         country_name:""
      }
   );

   useEffect(()=>{
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
         let data = {
            user_id :user.user_id
         };
         Api.driverList(data).then((response)=>{
            if(response && response.data && response.data.users.length>0){
               setCompleteData(response.data.users);
               setNewData((
                  response.data.users.slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
            }else{
               setLoader(false);
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }else{
         setLoader(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]);

   const habndelProfile = (e) => {
      const newPerfil = { ...editProfile };
      newPerfil[e.target.name] = e.target.value;
      setEditProfilel(newPerfil);
   };

   const submitHandler = (e) => { 
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('id',editProfile.user_id);
      data.append('firstname', editProfile.firstname);
      data.append('lastname', editProfile.lastname);
      data.append('email', editProfile.email);
      data.append('country_code', editProfile.country_code);
      data.append('phone', editProfile.phone);
      data.append('house_no', editProfile.house_no);
      data.append('street_name', editProfile.street_name);
      data.append('street_suf', editProfile.street_suf);
      data.append('country_name', editProfile.country_name);
      data.append('postcode', editProfile.postcode);
      data.append('state', editProfile.state);
      data.append('suburb', editProfile.suburb);
      data.append('company_name', editProfile.company_name);

      if(editProfile.firstname === "" || editProfile.lastname === "" || editProfile.email === "" || editProfile.phone === "" || editProfile.house_no === "" ||  editProfile.street_name ==="" || editProfile.country_name ==="" || editProfile.street_suf ==="" || editProfile.postcode ==="" || editProfile.state ==="" ||editProfile.suburb ===""){
         swal("Alerta", "Todos los campos son necesarios", "error");   
      }else{
         Swal.fire({
            text: '¿Seguro que quieres guardar los cambios?',
            title: 'Alerta',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: `Guardar`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
               setLoader(true);  
                  Api.updateProfile(data).then((response)=>{
                     // console.log(response);
                     if(response && response.result === "success"){
                        setDisabledInputs(!disabledInputs);
                        setModal(false);
                        setLoader(false);
                        Swal.fire('¡Guardado con exito!', '', 'success');
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     setLoader(false);
                  });
            }else{
                  setDisabledInputs(!disabledInputs);
            }
         })        
      }     
   }

   const disableUser = (item, a, index) => { 
      let FormData = require('form-data');
      let data = new FormData();
      const value = a === "activo" ? "1" : "2";
      data.append('id',item.user_id);
      data.append('user_status', value);
      const estados = a === "activo" ? "activar":"Desactivar";
      const alertestados = a === "activo" ? "activado":"Desactivado";
         Swal.fire({
            title: 'Alerta',
            icon: 'info',
            text: '¿Seguro que quieres '+estados+' a '+item.firstname+' ?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: a === "activo" ? `Activar`:`Desactivar`,
         }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                  Api.updateProfile(data).then((response)=>{
                     console.log(response)
                     if(response && response.result === "success"){
                        const data1 = newdata.slice();
                        data1[index].user_status = value;
                        setNewData(data1);
                     }
                  }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     setLoader(false);
                  });
                  Swal.fire('¡Conductor '+alertestados+'  con exito!', '', 'success');
            }else{
                  setDisabledInputs(!disabledInputs);
            }
         })           
   }

   const updateButton = () => {
      setDisabledInputs(!disabledInputs);
   }

   const modalEnable = (item ,index) => {
      setLoader(true);
      Api.adminGetUser({id: item.user_id}).then((response)=>{
         if(response && response.data){ 
            setModal(true);
            setLoader(false);
            setEditProfilel(response.data);
            // console.log(response.data)
         }
      }).catch( (error)=> {
      swal("Alerta",error, "error");
      console.log(error);
      setLoader(false);
      });
   };

   const modalDisable = (item) => {
      setModal(false);
      setDisabledInputs(true);
   }

   const [numberSort, setNumberSort] = useState(50);
   const chageSort = (n) => {
      setNumberSort(n);
      setNewData(
         completeData.slice(
                  activePag * n,
                  (activePag+ 1) * n
               )
      )
      setActivePag(0);
   }
   let jobPagination = Array(Math.ceil(completeData.length / numberSort))
   .fill()
   .map((_, i) => i + 1);

   const onClick = (i) => {
      setActivePag(i);
      setNewData(
         completeData.slice(
                  i * numberSort,
                  (i+ 1) * numberSort
               )
      )
   }
   
   const stateName = [
      "Activo",
      "Inactivo"
   ]

   const stateBag = [
      "success",
      "danger"
   ]
      
   const habndelInputSearch = (e) => {
      const newSearch = { ...search };
      newSearch[e.target.name] = e.target.value;
      setSearch(newSearch);
   };

   const handleSearch = () =>{
      setLoader(true);
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
         let data = {
            user_id:user.user_id,
            query:search.search,
         };
         Api.driverList(data).then((response)=>{
            console.log(response)
            if(response && response.data && response.data.users.length>0){
               setCompleteData(response.data.users);
               setNewData((
                  response.data.users.slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
               }else{
               setCompleteData([]);
               setNewData((
                  [].slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }
   }
   
   return (
      <Fragment>
         <div className="col-12">
            <div className="card">
               <Card.Header className="bgl-dark form-head d-flex justify-content-between">
                  <Col>
                     <Form.Label className="ml-4"> </Form.Label>
                     <Col>
                     <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary"
                        table="table-to-xls"
                        filename="conductores"
                        sheet="conductores"
                        buttonText="Exportar"
                     />
                     <Dropdown className="dropdown ml-3 d-inline-block">
                        <Dropdown.Toggle variant="" className="text-black bgl-light">
                           {numberSort}
                           <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                 <rect x="0" y="0" width="24" height="24"></rect>
                                 <circle fill="#000000" cx="12" cy="5" r="2"></circle>
                                 <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                 <circle fill="#000000" cx="12" cy="19" r="2"></circle>
                              </g>
                           </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="">
                           <Dropdown.Item onClick={(n) => chageSort(n=10)}>10</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=20)}>20</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=30)}>30</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=50)}>50</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageSort(n=100)}>100</Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                     </Col>
                  </Col>
                  <InputGroup className="input-group search-area d-inline-flex bgl-light">
                     <FormControl type="text" className="form-control text-black " name="search" value={search.search} onChange={habndelInputSearch} placeholder="Buscar aqui..." />
                     <InputGroup.Append>
                        <InputGroup.Text className="input-group-text">
                           <Link type="submit"  onClick={()=>handleSearch()}>
                              <i className="flaticon-381-search-2"></i>
                           </Link>
                        </InputGroup.Text>
                     </InputGroup.Append>
                  </InputGroup>
               </Card.Header>
               <div className="card-body pt-0">
                  <div className="default-tab">
                  {newdata.length !== 0 ?
                     <Table responsive hover id="table-to-xls">
                        <div id="example_wrapper" className="dataTables_wrapper">
                           <table className="display w-100 ">
                              <thead>
                                 <tr>
                                    <th>ID</th>
                                    <th>Foto</th>
                                    <th>Nombre</th>
                                    <th>Teléfono</th>
                                    <th>Estado</th>
                                    <th>Acción</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {
                                    newdata.map((item,index)=>{
                                       return(
                                          <tr key={index}>
                                                <td>{item.user_id}</td>
                                                <td>
                                                   <div className="media">
                                                      <img className="mr-2 img-fluid rounded-circle" width="50" style={{height:50}} src={item.profile_image !== "" ? `${server_image}${item.profile_image}`:`${server_image}${profile_image}`} alt="PikImages" />
                                                   </div>
                                                </td>
                                                <td>
                                                   <span className="w-space-no">
                                                      {item.firstname} {item.lastname}
                                                   </span>
                                                </td>
                                                <td>{item.country_code}-{item.phone}</td>
                                                <td>
                                                   <span className="w-space-no">
                                                   <Badge variant={stateBag[item.user_status -1]}>{stateName[item.user_status -1]}</Badge>
                                                   </span>
                                                </td>
                                                <td>
                                                   <Link onClick={() => modalEnable(item ,index)} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-eye"></i>
                                                   </Link>

                                                   {item.user_status === "1" ? <Link onClick={() => disableUser(item ,"",index)} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-ban"></i>
                                                   </Link>:<Link onClick={() => disableUser(item ,"activo", index )} className="btn btn-dark shadow btn-xs sharp mr-1">
                                                      <i className="text-light fa fa-check"></i>
                                                   </Link>}
                                                </td>
                                             </tr>
                                       )
                                    })
                                 }
                              </tbody>
                              <tfoot>
                                 <tr>
                                    <th>ID</th>
                                    <th>Foto</th>
                                    <th>Nombre</th>
                                    <th>Teléfono</th>
                                    <th>Estado</th>
                                    <th>Acción</th>
                                 </tr>
                              </tfoot>
                           </table>
                           <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="dataTables_info">
                              <p>Mostrando de {activePag * numberSort + 1} a {completeData.length < (activePag + 1) * numberSort ? completeData.length : (activePag + 1) * numberSort}  de {completeData.length} Conductores</p>      
                              </div>
                              <div className="dataTables_paginate paging_simple_numbers">
                                 <Pagination
                                    className="pagination-primary"
                                    size="lg"
                                 >
                                    <li
                                       className="page-item page-indicator "
                                       onClick={() =>
                                          activePag > 0 &&
                                          onClick(activePag -1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-left" />
                                       </Link>
                                    </li>
                                    {jobPagination.map((number, i) => (
                                       <Pagination.Item
                                          className={
                                             activePag === i ? "active" : ""
                                          }
                                          onClick={() => onClick(i)}
                                       >
                                          {number}
                                       </Pagination.Item>
                                    ))}
                                    <li
                                       className="page-item page-indicator"
                                       onClick={() =>
                                          activePag + 1 <
                                             jobPagination.length &&
                                          onClick(activePag + 1)
                                       }
                                    >
                                       <Link className="page-link" to="#">
                                          <i className="la la-angle-right" />
                                       </Link>
                                    </li>
                                 </Pagination>
                              </div>
                           </div>
                        </div>
                     </Table>:
                     <div className="align-items-center pt-3 pb-3">
                        <p className="text-center">No hay Conductores</p>
                     </div>}
                  </div>
               </div>
            </div>
         </div>
         <Modal
            className="fade"
            show={modal}
            size= {modalType !== "detalle" ? "lg":"xl"}
         >
            <Modal.Header className={"bgl-"+stateBag[editProfile.user_status -1]+""} >
               <Modal.Title>Detalle del Conductor ID #{editProfile.user_id}</Modal.Title>
               <Loader
                  type="Puff"
                  color="#00dcc6"
                  height={50}
                  width={50}
                  // timeout={3000} //3 secs
                  visible={loader}	
                  className="text-center"
               />
               <Button  className="btn btn-light" onClick={() => updateButton()} >
               <i className="fa fa-pencil mr-2"></i>
                  Editar
               </Button>
            </Modal.Header>
            <Modal.Body>
               <div className="basic-form">
                  <form action="" onSubmit={(e) => submitHandler(e)}>
                     <div className="form-row">
                        <div className="form-group col-md-12">
                           <div className="row justify-content-between pl-3 pr-3">
                              <label>Nombre de la Empresa</label>
                              <label className="text-right">Tipo de Vehiculo ({editProfile.vehicle_type})</label>
                           </div>
                           <input
                              type="text"
                              className="form-control"
                              name="company_name"
                              value={editProfile.company_name}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Nombre</label>
                           <input
                              type="text"
                              className="form-control"
                              name="firstname"
                              value={editProfile.firstname}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Apellido</label>
                           <input
                              type="text"
                              className="form-control"
                              name="lastname"
                              value={editProfile.lastname}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-6">
                           <label>Email</label>
                           <input
                              readOnly
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={editProfile.email}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-2">
                           <label>Código</label>
                           <input
                              type="number"
                              className="form-control"
                              name="code"
                              value={editProfile.country_code}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-4">
                           <label>Teléfono</label>
                           <input
                              type="text"
                              className="form-control"
                              name="phone"
                              value={editProfile.phone}
                              onChange={habndelProfile}
                              disabled={disabledInputs}
                           />
                        </div>
                        <div className="form-group col-md-3">
                           <label>No. Casa</label>
                           <input type="text" className="form-control"  value={editProfile.house_no} name="house_no" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-3">
                           <label>Nombre de la Calle</label>
                           <input type="text" className="form-control" value={editProfile.street_name} name="street_name" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-3">
                           <label>No. Calle</label>
                           <input type="text" className="form-control" value={editProfile.street_suf} name="street_suf" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-3">
                           <label>Código Postal</label>
                           <input type="text" className="form-control" value={editProfile.postcode} name="postcode" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-4">
                           <label>Ciudad</label>
                           <input type="text" className="form-control" value={editProfile.suburb}name="suburb" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-4">
                           <label>Estado</label>
                           <input type="text" className="form-control" value={editProfile.state} name="state" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                        <div className="form-group col-md-4">
                           <label>País</label>
                           <input type="text" className="form-control" value={editProfile.country_name} name="country_name" onChange={habndelProfile} disabled={disabledInputs}/>
                        </div>
                     </div>
                     {disabledInputs ? null:<button type="submit" className="btn btn-primary" onClick={() => submitHandler} >
                        Guardar
                     </button>}
                  </form>
               </div>
            </Modal.Body>
            <Modal.Footer className={"bgl-"+stateBag[editProfile.user_status -1]+" justify-content-end"}>  
               <Button
                  variant="light light"
                  onClick={() => modalDisable()}
               >
                  Cerrar
               </Button>
            </Modal.Footer>
         </Modal>
         <Modal
            centered
            show={loader}
            size= "sm"
            animation={false}
            timeout={3000}
            backdrop={false}
            contentClassName={"border-0 bg-transparent"}
         >
            <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
         </Modal>
      </Fragment>
   );  
};

export default Conductores;
