import React, { Fragment, useState, useEffect, Component } from "react";
import {
   Modal,
   Card,
   Col
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Apexspline from '../components/Apexspline';
import ChartPie from '../components/pie';
import {  Sparklines,  SparklinesLine,  } from "react-sparklines";
import Api from "../../modules/api";
import swal from "sweetalert";
import { server_image } from "../../services/config"
import Loader from "react-loader-spinner";

const profile_image = "52e164499740b60ce6e0fdaefd2c9372.jpg";

export class BoxUp extends Component{
    render() {
        return (
            <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="card overflow-hidden">
                    <div>
                        <div className="card-header media border-0 pb-0">
                            <div className="media-body">
                                <h2 className="text-black">{this.props.cant} <span className={this.props.porc > 0 ? "text-success fs-14": this.props.porc < 0 ? 'text-danger fs-14':'text fs-14'}>{this.props.porc}%</span></h2>
                                <p className="mb-0 text-black">{this.props.name}</p>
                            </div>
                        </div>
                        <div className="card-body pt-4 p-0">
                            <Sparklines data={this.props.sample}>
                                <SparklinesLine style={{ strokeWidth: 2}}  color="#00DCC6" />
                            </Sparklines>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

const Dashboard = () => {   
    const [topDriver, setTopDriver] = useState([]);
    const [totalPedidos, setTotalPedidos] = useState([]);
    const [sampleData1, setSampleData1] = useState([]);
    const [sampleData2, setSampleData2] = useState([]);
    const [sampleData3, setSampleData3] = useState([]);
    const [orders_porcent, setorders_porcent]= useState("");
    const [success_porcent, setsuccess_porcent]= useState("");
    const [cancel_porcent, setcancel_porcent]= useState("");
    const [loader, setLoader] = useState(true);
    const [income, setIncome] = useState([]);
    const [canceled, setCanceled] = useState({
        data:[],
        labels:[]
    });
    const [totalIncome, setTotalIncome] = useState(0);

    useEffect(()=>{
        const userData = localStorage.getItem('userData');
        const user = JSON.parse(userData); 
        if(userData){
            let FormData = require('form-data');
            let data = new FormData();
            data.append('user_id', user.user_id);
            Api.topDrivers(data).then((response)=>{
                // console.log(response,"topDrivers")
            if(response && response.data && response.data.length > 0){
                setTopDriver(response.data);
                Api.totalPedidos(data).then((response)=>{
                    // console.log(response,"totalPedidos")
                    if(response && response.data){
                        setTotalPedidos(response.data);
                        setSampleData1(response.data.pedidosGroup);
                        setSampleData2(response.data.aceptadosGroup);
                        setSampleData3(response.data.canceladosGroup);
                        setorders_porcent(parseFloat(response.data.orders_porcent).toFixed(2));
                        setsuccess_porcent(parseFloat(response.data.success_porcent).toFixed(2));
                        setcancel_porcent(parseFloat(response.data.cancel_porcent).toFixed(2));
                        Api.income(data).then((response)=>{
                            // console.log(response,"income")
                            if(response && response.data && response.grandTotal){
                                setIncome(response.data);
                                setTotalIncome(response.grandTotal);
                                setLoader(false);
                                Api.canceledReason(data).then((response)=>{
                                    // console.log(response,"canceledReason")
                                    if(response.response === true){
                                        // console.log(response.data);      
                                        setCanceled(response.data);
                                        setLoader(false);

                                    }
                                    setLoader(false);
                                }).catch( (error)=> {
                                    swal("Alerta",error, "error");
                                    console.log(error);
                                    setLoader(false);
                                });
                            }
                        }).catch( (error)=> {
                            swal("Alerta",error, "error");
                            console.log(error);
                            setLoader(false);
                        });
                    }
                }).catch( (error)=> {
                swal("Alerta",error, "error");
                console.log(error);
                });
            }else{
                setLoader(false);
            }
            }).catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
            });
            
            
        }
     },[]);

     const periodo = [
         "Día",
         "Semana",
         "Mes",
         "Año"
     ];

     const [pedidos, setPedidos] = useState(1);
     const chagepedidos = (n) => {
        setPedidos(n);
     };

     const [aceptados, setAceptados] = useState(1);
     const chageaceptados = (n) => {
        setAceptados(n);
     };

     const [rechazados, setRechazados] = useState(1);
     const chagerechazados = (n) => {
        setRechazados(n);
     };

    return (
        <Fragment>
            <div className="col-xl-12 col-lg-12 col-sm-12">
                <div className="row">    
                    <BoxUp loader={loader} onClick={(n) => chagepedidos(n)} periodoSelect={periodo[pedidos -1]} cant={totalPedidos.totalorders} porc={orders_porcent} name={totalPedidos.totalorders !== "1" ? "Total Pedidos":"Pedido"} sample={sampleData1}/>
                    <BoxUp loader={loader} onClick={(n) => chageaceptados(n)} periodoSelect={periodo[aceptados -1]} cant={totalPedidos.totalsuccess} porc={success_porcent} name={totalPedidos.totalsuccess !== "1" ? "Pedidos Aceptados":"Pedido Aceptado"} sample={sampleData2}/>
                    <BoxUp loader={loader} onClick={(n) => chagerechazados(n)} periodoSelect={periodo[rechazados -1]} cant={totalPedidos.totalcancel} porc={cancel_porcent} name={totalPedidos.totalcancel !== "1" ? " Pedidos Rechazados":"Pedido Rechazado"} sample={sampleData3}/>
                </div>
            </div>
            <div className="col-xl-12 col-lg-12">   
                <div className="row">    
                    <div className="col-xl-6 col-xxl-6 col-lg-6 col-sm-12">
                        <div className="card">
                            <div className="card-header pb-0 border-0">
                                <div>
                                    <h4 className="card-title mb-2">Total de Ingresos</h4>
                                    <p className="fs-13 mb-0">Según tipo de pagos</p>
                                </div>
                            </div>
                            {loader ? null:
                            <div className="card-body px-0 px-sm-3">
                                <div className="d-flex justify-content-center">
                                    <div className="text-center px-2 px-md-5">
                                        <p className="fs-14 mb-1">Ingreso total del año</p>
                                        <h4 className="text-black font-w600 mb-0 fs-22">LPS. {totalIncome}</h4>
                                    </div>
                                </div>
                                <div>
                                    <Apexspline income={income}/>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <Col xl={3} xxl={3} lg={3}>
                        <Card>
                            <div className="card-header pb-0 border-0">
                                <div>
                                    <h4 className="card-title mb-2">Total Cancelados</h4>
                                    <p className="fs-13 mb-0">Según motivo de cancelación</p>
                                </div>
                            </div>
                            <Card.Body>
                                
                                <ChartPie canceled={canceled}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div className="col-xl-3 col-xxl-3 col-lg-3 col-sm-12">
                        <div className="card">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h4 className="card-title mb-0 mr-3 ">Top 5 Conductores</h4>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                {loader ? null:
                                <div className="widget-media trending-menus">
                                    <p className="mb-0 fs-14 ml-4 mr-2">Conductores con la mayor cantidad de servicios exitosos</p>
                                    <ul className="timeline">
                                        {
                                            topDriver.map((item,index)=>{
                                                return(
                                                    <li key={index}>
                                                        <div className="timeline-panel mb-0">
                                                            <div className="media mr-3">
                                                                <Link to={"/ecom-product-detail"}>	
                                                                    <img className="mr-2 img-fluid rounded-circle" style={{height:60}} src={item.profile_image !== "" ? `${server_image}${item.profile_image}`:`${server_image}${profile_image}`} alt="PikImages" />
                                                                </Link>	
                                                                <div className="number">#{index + 1}</div>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="mb-2"><Link to={"/ecom-product-detail"}  className="text-black">{item.firstname} {item.lastname}</Link></h5>
                                                                <div className="d-flex justify-content-between align-items-center">                                          
                                                                    <p className="mb-0">Total de Ordenes <strong className="text-black font-w500">{item.totalorders}</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                centered
                show={loader}
                size= "sm"
                animation={false}
                timeout={3000}
                backdrop={false}
                contentClassName={"border-0 bg-transparent"}
            >
                <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
            </Modal>
        </Fragment>
    );  
};

export default Dashboard;
