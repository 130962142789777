import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const userData = localStorage.getItem('userData');
    const user = JSON.parse(userData); 
    return user?.user_id
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    // Set the time that the access token will expire at
    //5minutos
    if(userToken)
    {
        let expiresAt = JSON.stringify((600000) + new Date().getTime());
        localStorage.setItem('userData', JSON.stringify(userToken));
        localStorage.setItem('expires_at', expiresAt);
    }else{
        localStorage.removeItem('userData');
        localStorage.removeItem('expires_at');
    }
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}