import React, { Fragment, useEffect, useState } from 'react';

import MapPicker from '../components/MapPicker';
import Api from "../../modules/api";
import swal from "sweetalert"; 
export default function UbicacionConductores() {
  const [mappoint , setMapPoint] = useState([]);
   // eslint-disable-next-line
  const driverIcon = ["../images/Motocicleta.png","../images/Carro.png","../images/Camioneta.png","../images/Camion.png"]
  useEffect(()=>{
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
          let data = {
              user_id :user.user_id,
          };
          Api.driverlocation(data).then((response)=>{
              if(response.response){
                let drivers = [];
                for(const key in response.data.mappoint){
                  const row = response.data.mappoint[key];
                  if(row.latitude!=='')
                  {
                    let driverIcon = "Motocicleta.png";
                    if(row.vehicle_type === "Motocicleta") driverIcon = "Motocicleta.png";
                    if(row.vehicle_type === "Carro") driverIcon = "Carro.png";
                    if(row.vehicle_type === "Camión") driverIcon = "Camioneta.png";
                    if(row.vehicle_type === "Camioneta") driverIcon = "Camion.png";
                    drivers.push({
                      latitude: parseFloat(row.latitude),
                      longitude: parseFloat(row.longitude),
                      firstname: row.firstname,
                      lastname : row.lastname,
                      icon: driverIcon
                    })
                  }
                }
                setMapPoint(drivers)
              }
          }).catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
          });
      }
  },[]);

    return (
      <Fragment>
        <MapPicker height={"400px"} zoom={14} markers={mappoint} polygons={[]}/>
      </Fragment>
    );
}