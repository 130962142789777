import React, { Fragment, useState,useEffect } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import Swal from "sweetalert2";

const KokiNav = ({ title , setToken}) => {
   const [toggle, setToggle] = useState("");
   // const [username, setUsername] = useState("");
   const [profile, setProfile] = useState("");
   const [profileVisible, isProfileVisible] = useState(false);
   const onClick = (name) => setToggle(toggle === name ? "" : name);
   const [userData, setUserData] = useState({});
   // const [userId , setUserId] = useState(null);

   const logout = () =>{
      Swal.fire({
         title: '¿Estas seguro?',
         text: "¿Seguro que quieres cerrar sección?",
         showCancelButton: true,
         cancelButtonText: 'Cancelar',
         confirmButtonText: `Cerrar`,
         icon: "warning",
         dangerMode: true,
      })
      .then((result) => {
         console.log(result)
         if (result.isConfirmed) {
           setToken(false);
         }
      })
   };

   useEffect(() => {
      function load(){
         const userData = localStorage.getItem('userData');
         if(userData){
            const user = JSON.parse(userData); 
            // setUserId(user.userId);
            setUserData(user);
            isProfileVisible(true);
            // setUsername(user.firstName);
            setProfile(user.profileImage);
         }
      }
      load();
   },[]);

   return (
      <Fragment>
         <NavHader />
         <SideBar 
            profileVisible={profileVisible}
            user={userData.admin_type}
         />
         <Header
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            username={userData.name}
            profile={profile}
            onLogout={logout}
            profileVisible={profileVisible}
         />
      </Fragment>
   );
};

export default KokiNav;
