import React  from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap"

const Header = ({toggle, onProfile, onLogout,username,profileVisible}) => {
   var path = window.location.pathname.split("/");
   // var name = path[path.length - 1].split("-");
   var copname = path[path.length - 1];
	var page_name 
	if(copname === "login")
      page_name = "Iniciar sesión";
   if(copname === "signup")
      page_name = "Regístrate";
   if(copname === "forgot")
      page_name = "Restablecer contraseña";
   if(copname === "")
      page_name = "Dashboard";
   if(copname === "pedidos")
      page_name = "Pedidos";
   if(copname === "config")
      page_name = "Configuraciones";
   if(copname === "clientes")
      page_name = "Clientes";
   if(copname === "conductores")
      page_name = "Conductores";
   if(copname === "administrador")
      page_name = "Administrador";
   if(copname === "perfil")
      page_name = "Perfil";
   if(copname === "zonas")
      page_name = "Zonas Restringidas";
   if(copname === "ubicaciones")
      page_name = "Ubicación de Conductores";
   
   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar text-primary"
                        style={{ textTransform: "capitalize" }}
                     >
                        {page_name}
                     </div>
                  </div>
                  {profileVisible &&
                  <ul className="navbar-nav header-right">
                     <li
                        className={`nav-item dropdown header-profile ${
                           toggle === "profile" ? "show" : ""
                        }`}
                        onClick={() => onProfile()}
                     >
                        <Link to={"#"}
                           className="nav-link "
                           role="button"
                           data-toggle="dropdown"
                        >
                           <div className="header-info">
								      <span style={{ textTransform: "capitalize" }} className="text-primary">{username}</span>
                           </div>
                        </Link>
                        <div
                           className={`dropdown-menu dropdown-menu-right ${
                              toggle === "profile" ? "show" : ""
                           }`}
                        >
                           {/* <Link
                              to="/perfil"
                              className="dropdown-item ai-icon"
                           >
                              <i className="fa fa-user fa-lg text-primary"></i>

                              <span className="ml-2">Perfil </span>
                           </Link> */}
                           <Button
                              to={"login"}
                              onClick={() => onLogout()} 
                              className="dropdown-item ai-icon"
                           >
                              <i className="fa fa-sign-out fa-lg text-danger"></i>
                              
                              <span className="ml-2">Cerrar sesión </span>
                           </Button>
                        </div>
                     </li>
					 
                  </ul>
                  }
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
