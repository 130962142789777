import React  from "react";
import history from "../history";

/// React router dom
import { Router, Switch, Route, Redirect } from "react-router-dom";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer"; 

/// Pages

import Login from "./auth/Login";
import useToken from './useToken';
import Pedidos from "./home/pedidos";
import Config from "./home/configuracion";
import Dash from "./home/dashboard";
import Clientes from "./home/clientes";
import Conductores from "./home/conductores";
import Administrador from "./home/administrador";
import Zonas from "./home/zonas";
import UbicacionConductores from "./home/ubicacionConductores"

const Markup = () => {
   const { token, setToken } = useToken();
   const routes = [
        /// Auth
       // { url: "login", component: Login },
       // { url: "signup", component: SignUp },
       // { url: "forgot", component: Forgot },

        /// dashboard
        { url: "", component: Dash },

        /// table
		{ url: "pedidos", component: Pedidos  },
        { url: "config", component: Config },
        { url: "clientes", component: Clientes },
        { url: "conductores", component: Conductores },
        { url: "administrador", component: Administrador },
        { url: "zonas" , component: Zonas},
        { url: "ubicaciones" , component: UbicacionConductores}

   ];





   if(!token){
    return (<Login setToken={setToken} />)
   }

   return (	
    <Router history={history} basename="/">
        <div id="main-wrapper" className="show">
            <Nav setToken={setToken} />
            <div className="content-body">
                <div className="container-fluid">
                    <Switch>
                        {routes.map((data, i) => (
                            <Route
                                key={i}
                                exact
                                path={`/${data.url}`}
                                component={data.component}
                            />
                        ))}
                         <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </div>
            </div>
            <Footer />
        </div>
    </Router>
   ); 
};

export default Markup;
