import React from "react";
// import {Link} from 'react-router-dom';
import moment from "moment";
const Footer = () => {
   return (
      <div className="footer">
         <div className="copyright">
            <p>
               Copyright © {moment().format("YYYY")} Pik - All Rights Reserved. | {" "} 
               <a className="text-primary" href="https://pik.hn/" target="_blank" rel="noreferrer">
               Pik
               </a>{" "}
               es parte de GIPerez Group.
            </p>
         </div>
      </div>
   );
};

export default Footer;
