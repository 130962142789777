import React from "react";
import { Pie } from "react-chartjs-2";

const ChartPie = ({ color1, color2, color3, color4, height, width, canceled }) => {
    const data = {
        datasets: [
            {
                data:canceled.data,
                borderWidth: 0,
                backgroundColor: [
                `${color1 ? color1 : "#009C8C78"}`,
                `${color2 ? color2 : "#00B7EB"}`,
                `${color3 ? color3 : "#0087D178"}`,
                `${color4 ? color4 : "#C4C4C4"}`,
                ],
                hoverBackgroundColor: [
                `${color1 ? color1 : "#009C8C78"}`,
                `${color2 ? color2 : "#00B7EB"}`,
                `${color3 ? color3 : "#0087D178"}`,
                `${color4 ? color4 : "#C4C4C4"}`,
                ],
            },
        ],
        labels:canceled.labels,
    };

   const options = {
      responsive: true,
      legend: false,
      maintainAspectRatio: false,
   };

   return (
      <>
         <Pie data={data} height={height ? height : 330} options={options} />
      </>
   );
};

export default ChartPie;
