import React, { Fragment, useState, useEffect } from "react";
import {
   Table,
   Badge,
   Modal,
   Button,
   Pagination,
   Form,
   Card,
   Col,
   InputGroup,
   FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import Api from "../../modules/api";
import swal from "sweetalert";
import { server_image } from "../../services/config"
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import 'moment/locale/es' 

const Pedidos = () => {
   const [modal, setModal] = useState(false);
   const [modalConductor, setModalConductor] = useState(false);
   const [modalType, setModalType] = useState("");
   const [modalData, setModalData] = useState([]);
   const [completeData, setCompleteData] = useState([]);
   const [newdata , setNewData] = useState([]);
   const [activePag, setActivePag] = useState(0);
   const [loader, setLoader] = useState(false);
   const [driverData, setDriverData] = useState([]);
   const [orderId, setOrderId] = useState([]);
   const [index, setIndex]= useState("");
   const profile_image = "52e164499740b60ce6e0fdaefd2c9372.jpg";
   const [select, setSelect] = useState(10);
   const [selectedInitialDate, setSelectedInitialDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
   const [selectedFinalDate, setSelectedFinalDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
   const [search, setSearch] = useState({search:""});

   useEffect(()=>{
      setLoader(true);
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
         let data = {
            user_id :user.user_id,
            status: select === 10 ? undefined:select,
            date_1:selectedInitialDate,
            date_2:selectedFinalDate
         };
         Api.orderList(data).then((response)=>{
            if(response.result === "success" ){
               setCompleteData(response.data);
               setNewData((
                     response.data.slice(
                        activePag * numberSort,
                        (activePag + 1) * numberSort
                     )
                  ))
               setLoader(false);
               
            }else{
               setCompleteData([]);
               setNewData([]);
               setLoader(false);
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }else{
         setLoader(false);
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
   },[selectedInitialDate,selectedFinalDate,select]);

   const modalEnable = (item, a) => {
      setModal(true);
      console.log(item)
      setModalData(item);
      setModalType(a);
   };
   
   const modalClient = (item, index, a) => {
      setLoader(true);
      Api.adminGetUser({id: item.user_id}).then((response)=>{
         if(response && response.data){
            setModal(true);
            setModalData(response.data);
            setModalType(a);
            setLoader(false);
         }
      }).catch( (error)=> {
      swal("Alerta",error, "error");
      console.log(error);
      setLoader(false);
      });
   }

   const modalDriver = (item, index, a) => {
      setLoader(true);
      Api.adminGetUser({id: item.user_id}).then((response)=>{         
         if(response && response.data){
            // console.log(response.data.users[index]);
            setModal(true);
            setModalData(response.data);
            setModalType(a);
            setLoader(false);
         }
      }).catch( (error)=> {
      swal("Alerta",error, "error");
      console.log(error);
      setLoader(false);
      });
   };

   const removeOrder = (item, index, a) => {
      setLoader(true);
      console.log(index)
      Swal.fire({
         title: '¿Estas seguro?',
         text: "¿Seguro que quieres recahazar el pedido?",
         showCancelButton: true,
         cancelButtonText: 'Cancelar',
         confirmButtonText: `Rechazar`,
         icon: "warning",
         dangerMode: true,
      })
      .then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
               let FormData = require('form-data');
               let data = new FormData();
               data.append('id', item.order_id);
               Api.deleteOrder(data).then((response)=>{
                  if(response && response.result === "success"){
                     const data1 = newdata;
                     data1[index].delivery_status = "9";
                     setNewData(data1)
                     setLoader(false);
                  }
               }).catch( (error)=> {
               swal("Alerta",error, "error");
               console.log(error);
               setLoader(false);
            });
         }else{
            setLoader(false);
         }
     });
   };

   const selectDrive = (item, index) =>{
      setLoader(true);
      let data = {
         user_id :item.user_id
      };
      Api.driverList(data).then((response)=>{
         if(response && response.data && response.data.users.length >0 ){
            const driver_active = response.data.users.filter((item)=> {return item.user_status === "1" })
            setModalConductor(true)
            setDriverData(driver_active);
            setOrderId(item);
            setIndex(index);
            setLoader(false);
         }
      }).catch( (error)=> {
      swal("Alerta",error, "error");
      console.log(error);
      setLoader(false);
      });
   }

   const asignarDriver = (item) => {
      console.log(orderId.order_id,"orderId.order_id")
      console.log(item.user_id,"item.user_id")
      let FormData = require('form-data');
      let data = new FormData();
      data.append('id', orderId.order_id);
      data.append('driver', item.user_id);
      Swal.fire({
         title: '¿Estas seguro?',
         text: "¿Seguro que quieres asignar este conductor?",
         showCancelButton: true,
         cancelButtonText: 'Cancelar',
         confirmButtonText: `Asignar`,
         icon: "warning",
         dangerMode: true,
      })
      .then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {

               Api.updateDriver(data).then((response)=>{
                  if(response && response.result === "success"){
                     const data1 = newdata;
                     data1[index].driver_first = item.firstname;
                     data1[index].driver_last = item.lastname;
                     data1[index].driver_id = item.user_id;
                     data1[index].delivery_status = 6;
                     setNewData(data1)
                     setLoader(false);
                     setModalConductor(false)
                  }
               }).catch( (error)=> {
               swal("Alerta",error, "error");
               console.log(error);
               setLoader(false);
            });
         }else{
            setLoader(false);
         }
     });
   };
   
   const [numberSort, setNumberSort] = useState(50);
   const chageSort = (n) => {
      setNumberSort(n);
      setNewData(
         completeData.slice(
            activePag * n,
            (activePag+ 1) * n
         )
      )
      setActivePag(0);
   }

   let jobPagination = Array(Math.ceil(completeData.length / numberSort))
      .fill()
      .map((_, i) => i + 1);

   const onClick = (i) => {
      setActivePag(i);
      setNewData(
         completeData.slice(
            i * numberSort,
            (i+ 1) * numberSort
         )
      )
   }

   const stateName = [
      "Pendiente",
      "Confirmado",
      "Cancelado",
      "Entregado",
      "Pagado",
      "Aceptado",
      "Recogido",
      "Retrazado",
      "Rechazado",
      "Todos"
   ];

   const stateBag = [
      "warning light",
      "warning",
      "danger",
      "success",
      "success light",
      "info light",
      "info",
      "dark",
      "danger light",
   ];

   const chageStatus = (n) => {
      setSelect(n);
   };

   const handleDateInitialChange = (newValue, date) => {
      
      if(date === "Inicial"){
         setSelectedInitialDate(moment(newValue).format("YYYY-MM-DD"));
      }
   }

   const handleDateFinalChange = (newValue, date) => {
      if(date === "Final"){
         setSelectedFinalDate(moment(newValue).format("YYYY-MM-DD"))
      }
   }

   const habndelInputSearch = (e) => {
      const newSearch = { ...search };
      newSearch[e.target.name] = e.target.value;
      setSearch(newSearch);
   };

   const handleSearch = () =>{
      setLoader(true);
      const userData = localStorage.getItem('userData');
      const user = JSON.parse(userData); 
      if(userData){
         let data = {
            user_id :user.user_id,
            status: select === 10 ? undefined:select ,
            date_1:selectedInitialDate,
            date_2:selectedFinalDate,
            query:search.search,
         };
         Api.orderList(data).then((response)=>{
            if(response.result === "success" ){
               setCompleteData(response.data);
               setSelect(data.status === undefined ? 10:data.status);
               setNewData((
                     response.data.slice(
                        activePag * numberSort,
                        (activePag + 1) * numberSort
                     )
                  ))
                  setLoader(false);
               }else{
               setCompleteData([]);
               setNewData((
                  [].slice(
                     activePag * numberSort,
                     (activePag + 1) * numberSort
                  )
               ))
               setLoader(false);
               // swal("Alerta",response.message, "warning");
            }
         }).catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setLoader(false);
         });
      }
   }

   return (
      <Fragment>
         <Col sm={12} lg={12} xl={12}>   
            <Card className="mb-5" style={{minHeight:500}}>
               <Card.Header className="bgl-dark form-head d-flex justify-content-between">
                  <Col lg={1}>
                     <Form.Label className="ml-3 text-black"></Form.Label>
                     <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary"
                        table="table-to-xls"
                        filename="pedidos"
                        sheet="pedidos"
                        buttonText="Exportar"
                     />
                  </Col>
                  <Col lg={2}>
                     <Form.Label className="ml-3 text-black"></Form.Label>
                     <Dropdown className="dropdown ml-3 ">
                     <Dropdown.Toggle variant="" className="text-black bgl-light">
                        {numberSort}
                        <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" version="1.1">
                           <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <circle fill="#000000" cx="12" cy="5" r="2"></circle>
                              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                              <circle fill="#000000" cx="12" cy="19" r="2"></circle>
                           </g>
                        </svg>
                     </Dropdown.Toggle>
                     <Dropdown.Menu className="">
                        <Dropdown.Item onClick={(n) => chageSort(n=10)}>10</Dropdown.Item>
                        <Dropdown.Item onClick={(n) => chageSort(n=20)}>20</Dropdown.Item>
                        <Dropdown.Item onClick={(n) => chageSort(n=30)}>30</Dropdown.Item>
                        <Dropdown.Item onClick={(n) => chageSort(n=50)}>50</Dropdown.Item>
                        <Dropdown.Item onClick={(n) => chageSort(n=100)}>100</Dropdown.Item>
                     </Dropdown.Menu>
                  </Dropdown>
                  </Col>
                  <Col lg={2}>
                     <Form.Label className="ml-3 text-black">Estado</Form.Label>
                     <Dropdown className=" ">
                        <Dropdown.Toggle variant="button" className="btn bgl-light border btn-rounded text-black dropdown-toggle">
                              {stateName[select -1]}
                              <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item onClick={(n) => chageStatus(n=1)}>Pendiente</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=2)}>Confirmado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=3)}>Cancelado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=4)}>Entregado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=5)}>Pagado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=6)}>Aceptado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=7)}>Recogido</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=8)}>Retrazado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=9)}>Rechazado</Dropdown.Item>
                           <Dropdown.Item onClick={(n) => chageStatus(n=10)}>Todos</Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </Col>
                  <Col lg={2}>
                     <Form.Label className="ml-3 text-black">Fecha Inicial</Form.Label>
                     <div className="form-material d-flex">
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale('es')}>
                           <DatePicker
                              locale={moment.locale('es')}
                              label=""
                              name={"inicial"}
                              format="DD/MM/YYYY"
                              value={selectedInitialDate}
                              onChange={(newValue) => {
                                 let date ="Inicial"
                                 handleDateInitialChange(newValue, date);
                               }}                              
                              className="btn bgl-light border btn-rounded text-black dropdown-toggle mr-2 pt-1 pb-1"
                              cancelLabel="Cancelar"
                              clearable={false}
                           />
                        </MuiPickersUtilsProvider>
                     </div>
                  </Col>
                  <Col lg={2}>
                     <Form.Label className="ml-3 text-black">Fecha Final</Form.Label>
                     <div className="form-material d-flex">
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale('es')}>
                           <DatePicker
                              locale={moment.locale('es')}
                              label=""
                              name={"inicial"}
                              format="DD/MM/YYYY"
                              value={selectedFinalDate}
                              onChange={(newValue) => {
                                 let date ="Final"
                                 handleDateFinalChange(newValue, date);
                               }}                              
                              className="btn bgl-light border btn-rounded text-black dropdown-toggle mr-2 pt-1 pb-1"
                              cancelLabel="Cancelar"
                              clearable={false}
                           />
                        </MuiPickersUtilsProvider>                     
                     </div>
                  </Col>
                  <Col lg={3}>
                     <Form.Label className="ml-3 text-black">Buscar:</Form.Label>
                     {/* <Form action="" onSubmit={(e) => handleSearch(e)}> */}
                        <InputGroup className="input-group search-area d-inline-flex bgl-light">
                           <FormControl type="text" className="form-control text-black " name="search" value={search.search} onChange={habndelInputSearch} placeholder="Buscar aqui..." />
                           <InputGroup.Append>
                              <InputGroup.Text className="input-group-text">
                                 <Link type="submit"  onClick={()=>handleSearch()}>
                                    <i className="flaticon-381-search-2"></i>
                                 </Link>
                              </InputGroup.Text>
                           </InputGroup.Append>
                        </InputGroup>
                     {/* </Form> */}
                  </Col>
               </Card.Header>
               <Card.Body>
               {newdata.length !== 0 ?
                  <Table responsive hover id="table-to-xls">
                     <div id="example_wrapper" className="dataTables_wrapper">
                        <thead>
                           <tr>
                              <th>#Pedido</th>
                              <th>Fecha</th>
                              <th>Costo</th>
                              <th>Cliente</th>
                              <th>Conductor</th>
                              <th>Estado</th>
                              <th>Acción</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              newdata.map((item,index)=>{
                                 return(
                                    <tr key={index}>
                                       <td>
                                          {item.order_id}
                                       </td>
                                       <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                       <td>
                                          <span className="text-muted">
                                             LPS. {item.delivery_cost}
                                          </span>
                                       </td>
                                       <td onClick={(a) => modalClient(item, index, a = "cliente")}>
                                          {item.firstname} {item.lastname}
                                       </td>
                                       <td >
                                       {item.driver_id === "0" ? 
                                          <div onClick={() => selectDrive(item, index)}>Asignar</div>
                                       :<div onClick={(a) => modalDriver(item, index, a = "conductor")}>
                                          {item.driver_first} {item.driver_last}
                                       </div>}
                                       </td>
                                       <td>
                                          <Badge variant={stateBag[item.delivery_status -1]}>{stateName[item.delivery_status -1]}</Badge>
                                       </td>
                                       <td>
                                          <Dropdown>
                                             <Dropdown.Toggle variant="ellipsis">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu >
                                                <Dropdown.Item>
                                                   <Link onClick={(a) => modalEnable(item, a = "detalle")} className="text-black">
                                                      <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                         <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#00DCC6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                         <path d="M12 16V12" stroke="#00DCC6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                         <path d="M12 8H12.01" stroke="#00DCC6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      </svg>
                                                      Detalle del pedido
                                                   </Link>
                                                </Dropdown.Item>
                                                {item.delivery_status !== "9" ? <Dropdown.Item> 
                                                   <Link onClick={(a) => removeOrder(item,index)} className="text-black">
                                                      <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                         <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                         <path d="M15 9L9 15" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                         <path d="M9 9L15 15" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      </svg>
                                                      Rechazar pedido
                                                   </Link>
                                                </Dropdown.Item>:null}
                                             </Dropdown.Menu>
                                          </Dropdown> 
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                        <tfoot>
                           <tr>
                              <th>#Pedido</th>
                              <th>Fecha</th>
                              <th>Costo</th>
                              <th>Cliente</th>
                              <th>Conductor</th>
                              <th>Estado</th>
                              <th>Acción</th>
                           </tr>
                        </tfoot>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                           <div className="dataTables_info">
                              <p>Mostrando de {activePag * numberSort + 1} a {completeData.length < (activePag + 1) * numberSort ? completeData.length : (activePag + 1) * numberSort}  de {completeData.length} Pedidos</p>      
                           </div>
                           <div className="dataTables_paginate paging_simple_numbers">
                              <Pagination
                                 className="pagination-primary"
                                 size="lg"
                              >
                                 <li
                                    className="page-item page-indicator "
                                    onClick={() =>
                                       activePag > 0 &&
                                       onClick(activePag -1)
                                    }
                                 >
                                    <Link className="page-link" to="#">
                                       <i className="la la-angle-left" />
                                    </Link>
                                 </li>
                                 {jobPagination.map((number, i) => (
                                    <Pagination.Item
                                       className={
                                          activePag === i ? "active" : ""
                                       }
                                       onClick={() => onClick(i)}
                                    >
                                       {number}
                                    </Pagination.Item>
                                 ))}
                                 <li
                                    className="page-item page-indicator"
                                    onClick={() =>
                                       activePag + 1 <
                                          jobPagination.length &&
                                       onClick(activePag + 1)
                                    }
                                 >
                                    <Link className="page-link" to="#">
                                       <i className="la la-angle-right" />
                                    </Link>
                                 </li>
                              </Pagination>
                           </div>
                        </div>
                     </div>
                  </Table>:
                  <div className="align-items-center pt-3 pb-3">
                     <p className="text-center">No hay pedidos</p>
                  </div>}
               </Card.Body>
            </Card>
         </Col>
         <Modal
            className="fade"
            show={modal}
            size= {modalType !== "detalle" ? "lg":"xl"}
         >
            <Modal.Header className={"bgl-"+stateBag[modalData.delivery_status]+""}>
               {modalType === "cliente" ? <Modal.Title>Cliente ({modalData.firstname} {modalData.lastname})</Modal.Title>:null}
               {modalType === "conductor" ? <Modal.Title>Conductor ({modalData.firstname} {modalData.lastname})</Modal.Title>:null}
               {modalType === "detalle" ? <Modal.Title>Detalle del Pedido</Modal.Title>:null}
               <Button
                  variant=""
                  className="close"
                  onClick={() => setModal(false)}
               >
                  <span>&times;</span>
               </Button>
            </Modal.Header>
            {modalType !== "detalle" ? 
               <Modal.Body>
                  <div className="align-items-center">
                     <div className="row profile-details align-items-center">
                        <div className="px-3 pt-2">
                           <img className="mr-2 img-fluid rounded-circle" width="100" style={{height:100}} src={modalData.profile_image !== "" ? `${server_image}${modalData.profile_image}`:`${server_image}${profile_image}`} alt="PikImages" />
                        </div>
                        <div className="px-3 pt-2">
                           <h4 className="text-primary">
                              {modalData.country_code}-{modalData.phone}
                           </h4>
                           <p>Telefono</p>
                        </div>
                        <div className="px-3 pt-2">
                           <h4 className="text-muted">
                              {modalData.email}
                           </h4>
                           <p>Email</p>
                        </div>
                     </div>
                  </div>
            </Modal.Body>:
            <Modal.Body>
               <div className="col-xl-12 col-xxl-12">
                  <div className="card-body px-0 px-sm-3">
                     <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-left">
                           <div className="text-left px-2 ">
                              <p className="fs-14 mb-1"># Pedido</p>
                              <h6 className="text-black font-w600 mb-0">{modalData.order_id}</h6>
                           </div>
                           <div className="text-left px-2 px-md-5">
                              <p className="fs-14 mb-1">Fecha y Hora</p>
                              <h6 className="text-black font-w600 mb-0">{modalData.delivery_date}  {modalData.delivery_time}</h6>
                           </div>
                        </div>
                        <div className="text-left ">
                           <Link to={"#"} className={modalType !== "detalle" ? "":"btn bgl-"+stateBag[modalData.delivery_status -1]+" text-"+stateBag[modalData.delivery_status -1]+""}>{stateName[modalData.delivery_status -1]}</Link>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6 col-md-12 col-xxl-6">
                        <div className="card">
                           <div className="card-body d-flex justify-content-between border-bottom ">
                              <div className="align-items-center ">
                                 <h6 className="text-black font-w600 ">Costo</h6>
                                 <p className=" mb-0 text-center">LPS. {modalData.delivery_cost}</p>   
                              </div>
                              <div className="align-items-center ">
                                 <h6 className="text-black font-w600 ">Método de pago</h6>
                                 {modalData.payment_method_id === "1" && <p className=" mb-0 text-center">Efectivo</p>} 
                                 {modalData.payment_method_id === "2" && <p className=" mb-0 text-center">Pixel Pay</p>}       
                                 {modalData.payment_method_id === null || modalData.payment_method_id === undefined ? <p className=" mb-0 text-center">no asignado</p>:null}                                                                                                                                                                                                                                                                                                                                                                      
                              </div>
                           </div>
                           <div className="card-body border-bottom">
                              <div className="media align-items-center ">
                                    <svg className="mr-1 mb-2" width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minWidth: "24px" }}>
                                    <path d="M28 13.3333C28 22.6667 16 30.6667 16 30.6667C16 30.6667 4 22.6667 4 13.3333C4 10.1507 5.26428 7.09848 7.51472 4.84805C9.76516 2.59761 12.8174 1.33333 16 1.33333C19.1826 1.33333 22.2348 2.59761 24.4853 4.84805C26.7357 7.09848 28 10.1507 28 13.3333Z" stroke="#3E4954" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M16 17.3333C18.2091 17.3333 20 15.5425 20 13.3333C20 11.1242 18.2091 9.33333 16 9.33333C13.7909 9.33333 12 11.1242 12 13.3333C12 15.5425 13.7909 17.3333 16 17.3333Z" stroke="#3E4954" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                 </svg>
                                 <h6 className="text-black font-w600 ">¿Donde Buscar?</h6>
                              </div>
                              <a href={"https://www.google.co.ve/maps/@"+modalData.pickup_lat+","+modalData.pickup_long+",20z?hl=es"} target="_blank" rel="noreferrer">
                                 <p className="mb-0 text-center">{`${modalData.pickupaddress} ${modalData.pickup_special_inst}`}</p>
                              </a>
                           </div>
                           <div className="card-body border-bottom">
                              <div className="media align-items-center">
                                 <svg className="mr-1 mb-2" width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minWidth: "24px" }}>
                                    <path d="M28 13.3333C28 22.6667 16 30.6667 16 30.6667C16 30.6667 4 22.6667 4 13.3333C4 10.1507 5.26428 7.09848 7.51472 4.84805C9.76516 2.59761 12.8174 1.33333 16 1.33333C19.1826 1.33333 22.2348 2.59761 24.4853 4.84805C26.7357 7.09848 28 10.1507 28 13.3333Z" stroke="#3E4954" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M16 17.3333C18.2091 17.3333 20 15.5425 20 13.3333C20 11.1242 18.2091 9.33333 16 9.33333C13.7909 9.33333 12 11.1242 12 13.3333C12 15.5425 13.7909 17.3333 16 17.3333Z" stroke="#3E4954" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                 </svg>
                                 <h6 className="text-black font-w600">¿Donde Entregar?</h6>
                              </div>
                              <a href={"https://www.google.co.ve/maps/@"+modalData.dropoff_lat+","+modalData.dropoff_long+",20z?hl=es"} target="_blank" rel="noreferrer">
                                 <p className="mb-0 text-center">{`${modalData.dropoffaddress} ${modalData.dropoff_special_inst}`}</p>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6 col-md-12 col-xxl-6">
                        <div className="card">
                           <div className="card-body border-bottom d-flex justify-content-between">
                              <div className="align-items-center ">
                                 <h6 className="text-black font-w600">Detalle del articulo</h6>
                                 <div className="justify-content-between">
                                    <p>{modalData.item_description}</p>
                                 </div>
                              </div>
                              <div className="align-items-center ">
                                 <h6 className="text-black font-w600">Cantidad</h6>
                                 <div className="justify-content-between">
                                    <p>x {modalData.item_quantity}</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card-body  center border-bottom">
                              <h6 className="text-black font-w600 ">¿Quién Entrega?</h6>
                              <div className="d-flex justify-content-between">
                                 <p>{modalData.pickup_first_name} {modalData.pickup_last_name}</p>
                                 <p>Tel. {modalData.pickup_country_code}-{modalData.pickup_mob_number}</p>
                              </div>
                           </div>
                           <div className="card-body center border-bottom">
                              <h6 className="text-black font-w600 ">¿Quién Recibe?</h6>
                              <div className="d-flex justify-content-between">
                                 <p>{modalData.dropoff_first_name} {modalData.dropoff_last_name}</p>
                                 <p>Tel. {modalData.dropoff_country_code}-{modalData.dropoff_mob_number}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     {modalData.delivery_status === "4" ?<div className="col-xl-12 col-md-12 col-xxl-12">
                        <div className="card">
                           <div className="card-body center ">
                              <h6 className="text-black font-w600 ">Confirmación de Recibo</h6>
                              <div>
                                 <div className="d-flex">
                                    <p className="text-black mr-2">Nombre: </p>
                                    <p>{modalData.signature_name}</p>
                                 </div>
                                 <div className="d-flex">
                                    <img className="mr-2 img-fluid " width="100" style={{height:100}} src={modalData.received_user_image !== "" ? `${server_image}${modalData.received_user_image}`:`${server_image}${profile_image}`} alt="PikImages" />
                                    <img className="mr-2 img-fluid " width="100" style={{height:100}} src={modalData.signature_image !== "" ? `${server_image}${modalData.signature_image}`:`${server_image}${profile_image}`} alt="PikImages" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>:null}
                  </div>
               </div>
            </Modal.Body>}
            <Modal.Footer className={"bgl-"+stateBag[modalData.delivery_status]+ modalType === "detalle" ? "justify-content-between":"justify-content-end"}>
               {modalType === "cliente" ? <Modal.Title>Cliente ID #{modalData.user_id}</Modal.Title>:null}
               {modalType === "conductor" ? <Modal.Title>Conductor ID #{modalData.user_id}</Modal.Title>:null}
               <Button
                  variant="light light"
                  onClick={() => setModal(false)}
               >
                  Cerrar
               </Button>
            </Modal.Footer>
         </Modal>
         <Modal
            className="fade"
            show={modalConductor}
            size= "lg"
         >
            <Modal.Header className={"bgl-"+stateBag[orderId.delivery_status -1]+""}>
               <Modal.Title>Asignar un Conductor al Pedido # {orderId.order_id}</Modal.Title>
               <Button
                  variant=""
                  className="close"
                  onClick={() => setModalConductor(false)}
               >
                  <span>&times;</span>
               </Button>
            </Modal.Header>
            <Modal.Body>
               <Table responsive="sm" hover >
                  <div id="example_wrapper" className="dataTables_wrapper scroll">
                     <table className="display w-100 ">
                        <thead>
                           <tr>
                              <th>#ID</th>
                              <th>Nombre</th>
                              <th>Vehiculo</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              driverData.map((item,index)=>{
                                 return(
                                    <tr key={index} onClick={(a) => asignarDriver(item, index)}>
                                       <td>
                                          <p>{item.user_id}</p>
                                       </td>
                                       <td><p>{item.firstname} {item.lastname}</p></td>
                                       <td>
                                          <span className="text-muted">
                                          <p>{item.vehicle_type}</p>
                                          </span>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                        <tfoot>
                           <tr>
                              <th>#ID</th>
                              <th>Nombre</th>
                              <th>Vehiculo</th>
                           </tr>
                        </tfoot>
                     </table>
                  </div>
               </Table>
            </Modal.Body>
            <Modal.Footer className="justify-content-end">
               <Button
                  variant="light light"
                  onClick={() => setModalConductor(false)}
               >
                  Cerrar
               </Button>
            </Modal.Footer>
         </Modal>
         <Modal
            centered
            show={loader}
            size= "sm"
            animation={false}
            timeout={3000}
            backdrop={false}
            contentClassName={"border-0 bg-transparent"}
         >
            <Loader type="Circles" color="#00dcc6" height={50} width={50} visible={true}  centered={true}	className="text-center"/>
         </Modal>
      </Fragment>
   );  
};

export default Pedidos;
